
export const emoticon = (text: string, start: number, end: number, emote: string): string => {
  const before = text.substr(0, start)
  const after = text.substr(end, text.length - before.length)
  return before + emote + after
}

export const tagMessage = (text: string, start: number, end: number, tag: string, popup: string) => {
  const length = end - start
  const before = text.substr(0, start)
  const after = text.substr(end, text.length - before.length)

  let insert = (length === 0) ? prompt(popup) : text.substr(start, length)

  if (insert !== null) {
    text = before + '[' + tag + ']' + insert + '[/' + tag + ']' + after
  }

  return text
}

export const color = (text: string, start: number, end: number, color: string): string => {
  const length = end - start

  const before = text.substr(0, start)
  const after = text.substr(end, text.length - before.length)

  const insert = (length === 0) ? prompt('Skriv in texten du vill färga:') : text.substr(start, length)

  if (insert !== null) {
    text = before + '[color=' + color + ']' + insert + '[/color]' + after
  }

  return text
}

export const useFont = (text: string, start: number, end: number, font: string): string => {
  const length = end - start

  const before = text.substr(0, start)
  const after = text.substr(end, text.length - before.length)

  const insert = (length === 0) ? prompt('Skriv in texten du vill formatera:') : text.substr(start, length)

  if (insert !== null) {
    text = before + '[font=' + font + ']' + insert + '[/font]' + after
  }

  return text
}

export const link = (text: string, start: number, end: number): string => {
  const url = prompt('Skriv in länken:')

  if (url !== null) {
    const length = end - start

    const before = text.substr(0, start)
    const after = text.substr(end, text.length - before.length)

    const insert = (length === 0) ? url : text.substr(start, length)

    if (insert !== null) {
      text = before + '[url=' + url + ']' + insert + '[/url]' + after
    }
  }

  return text
}
