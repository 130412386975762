import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getItemCodes, postItemCode } from "../redux/actions"
import { AppState } from "../redux/reducers"
import { Link } from "./utils/Link"


const NewItemCodeForm: React.FC = () => {
  const [expanded, setExpanded] = useState(false);
  const [code, setCode] = useState("");
  const state = useSelector((state: AppState) => state.itemCode)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getItemCodes())
  }, [])

  if (!expanded)
    return <p><button onClick={() => setExpanded(true)}>Lägg till</button></p>

  let codeTaken = false
  state.itemCodes.forEach(itemCode => {
    if (itemCode.code === code) {
      codeTaken = true
    }
  })

  return <>
    <h2>Lägg till</h2>
    <form
      onSubmit={(e) => {
        e.preventDefault();

        if (code.length <= 0 || codeTaken)
          return false

        const form = e.target as HTMLFormElement
        const name = form.elements["name"].value
        const image = form.elements["image"].files[0]

        if (name.length <= 0 || !image)
          return false

        dispatch(postItemCode(code, name, image))

        setCode('')
        form.elements["name"].value = ""
        form.elements["image"].value = ""
        setExpanded(false)

        return false
      }}
    >
      <table>
        <tbody>
          <tr>
            <td>Kod:</td>
            <td>
              <input name="code" type="text" value={code} onChange={(e) => setCode(e.target.value)} />
            </td>
            <td>
              {!codeTaken ? '' : <span style={{ color: "red" }}>Koden är redan tagen</span>}
            </td>
          </tr>
          <tr>
            <td>Namn:</td>
            <td>
              <input name="name" type="text" />
            </td>
            <td></td>
          </tr>
          <tr>
            <td>Bild:</td>
            <td>
              <input
                type="file"
                accept="image/png, image/jpeg"
                name="image"
              />
            </td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td>
              <input type="submit" value="Lägg till" />
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </form>
    <hr />
  </>
}

export const ItemCodeListPage: React.FC = () => {
  const [filter, setFilter] = useState("")
  const user = useSelector((state: AppState) => state.user.settings.user)
  const state = useSelector((state: AppState) => state.itemCode)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getItemCodes())
  }, [])

  if (!user || user.status.id < 3) return <></>

  const itemCodes = state.itemCodes
    .filter(
      (ic) => ic.code.toLowerCase().includes(filter) || ic.name.toLowerCase().includes(filter)
    )
    .sort((a, b) => a.name.localeCompare(b.name))

  return <>
    <h1>Ägodelstyper</h1>

    <NewItemCodeForm />

    <p>
      Filtrera: <input type="text" value={filter} onChange={(e) => setFilter(e.target.value.toLowerCase())} />
    </p>

    {itemCodes.isEmpty() ? '' :
      <table>
        <thead>
          <tr>
            <th colSpan={2}>Info</th>
            <th>Bild</th>
          </tr>
        </thead>
        <tbody>
          {itemCodes.map(itemCode => {
            return <React.Fragment key={itemCode.id}>
              <tr>
                <td>
                  Kod:
                </td>
                <td>
                  <Link url={`/admin/item-codes/${itemCode.id}`}>{itemCode.code}</Link>
                </td>
                <td rowSpan={2} style={{borderLeft: '1px solid black'}}>
                  <img src={itemCode.image} alt={itemCode.name} />
                </td>
              </tr>
              <tr>
                <td>Namn:</td>
                <td>{itemCode.name}</td>
              </tr>
            </React.Fragment>
          })}
        </tbody>
      </table>
    }
  </>
}
