import React from 'react'
import dayjs from 'dayjs-ext'
import timeZonePlugin from 'dayjs-ext/plugin/timeZone-1970-2038'
import 'dayjs-ext/locale/sv'

dayjs.extend(timeZonePlugin)
dayjs.locale('sv')

const timeZone = 'Europe/Stockholm'

const formatInTimeZone = (date: dayjs.Dayjs, template: string): string => {
  //@ts-ignore
  return date.format(template, {timeZone})
}

const isSameDay = (d1: dayjs.Dayjs, d2: dayjs.Dayjs): boolean =>
  formatInTimeZone(d1, 'YYYY-MM-DD') === formatInTimeZone(d2, 'YYYY-MM-DD')


interface FormattedDayProps {
  date: dayjs.Dayjs,
}

const FormattedDay: React.FC<FormattedDayProps> = ({date}) => {
  const now = dayjs()

  if (isSameDay(now, date))
    return <>Idag</>

  if (isSameDay(now.subtract(1, 'day'), date))
    return <>Igår</>

  return <span style={{whiteSpace: 'nowrap'}}>{formatInTimeZone(date, 'D MMM, YYYY')}</span>
}


interface FormattedDateProps {
  date: Date | string,
}

export const FormattedDate: React.FC<FormattedDateProps> = ({date}) => {
  if (!date)
      return <></>

  const parsedDate = dayjs(date);

  return <>
    <FormattedDay date={parsedDate} />
    <span> </span>
    <span style={{whiteSpace: 'nowrap'}}>{formatInTimeZone(parsedDate, 'H:mm')}</span>
  </>
}

export const formatDate = (date) => {
  return formatInTimeZone(dayjs(date), 'D MMM, YYYY H:mm')
}
