import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {useParams} from 'react-router-dom'
import { getGiftCalendar, getGiftCalendarUnlockDetails, listenForGiftCalendarPlacement, postGiftCalendarUnlock, registerGiftCalendarPlacement, updateGiftCalendarUnlock } from "../redux/actions"
import { findGiftCalendar, findGiftCalendarUnlockDetails, GiftCalendarUnlock, Position } from "../redux/state/gift_calendar"
import { AppState } from "../redux/reducers"
import {Spinner} from './utils/Spinner'
import { followURL } from "../utils"
import {findPosX, findPosY} from '../modules/scripts'
import { List } from "immutable"
import dayjs from "dayjs"


interface CoordinatesProps {
  position: Position,
}

const Coordinates: React.FC<CoordinatesProps> = ({position}) => <>[{position.x}, {position.y}]</>


interface GiftCalendarAdminUnlockRowProps {
  unlock: GiftCalendarUnlock,
}

const GiftCalendarAdminUnlockRow: React.FC<GiftCalendarAdminUnlockRowProps> = ({unlock}) => {
  const dispatch = useDispatch()
  const state = useSelector((state: AppState) => state.giftCalendar)
  const [answers, setAnswers] = useState(List(['']))

  useEffect(() => {
    dispatch(getGiftCalendarUnlockDetails(unlock.id))
  }, [unlock.id])

  const details = findGiftCalendarUnlockDetails(state, unlock.id)

  if (!details || details.loading) {
    return <Spinner />
  } else if (details.fail) {
    return <>Kunde inte ladda luckan.</>
  }

  const nRows = 2 + answers.size
  
  return <form
    onSubmit={(e) => {
      e.preventDefault();
      const form = e.target as HTMLFormElement

      const question = form.querySelector(`input[name="question[${unlock.id}]"]`) as HTMLInputElement
      const image = form.querySelector(`input[name="image[${unlock.id}]"]`) as HTMLInputElement
      const imageFile = (image && image.files[0]) ? image.files[0] : null

      const details = findGiftCalendarUnlockDetails(state, unlock.id)

      dispatch(updateGiftCalendarUnlock(unlock.id, imageFile, {
        pos1: unlock.pos1,
        pos2: unlock.pos2,
        task: {
          question: question.value,
          answers: answers.map((answer) => answer.trim())
                          .filter((answer) => answer.length > 0)
                          .toJS(),
          image: details.task.image,
          n_tries: 3,
        },
      }))

      return false
    }}
  >
    <h4>{unlock.name}</h4>
    <table>
      <thead>
        <tr>
          <th>Namn</th>
          <th>Placering</th>
          <th colSpan={2}>Uppgift</th>
          <th>Pris</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td rowSpan={nRows}>{unlock.name}</td>
          <td rowSpan={nRows}>
            <Coordinates position={unlock.pos1} />
            <br />
            <Coordinates position={unlock.pos2} />
            <br />
            <button onClick={() => dispatch(listenForGiftCalendarPlacement(unlock.id))}>Placera!</button>
          </td>
          <td>Bild:</td>
          <td>
            <input
              type="file"
              accept="image/png, image/jpeg"
              name={`image[${unlock.id}]`}
            />
            <br />
            <img src={details.task.image} alt={unlock.name} height={50} />
          </td>
          <td rowSpan={nRows}>TODO</td>
        </tr>
        <tr>
          <td>Fråga:</td>
          <td>
            <input
              type="text"
              name={`question[${unlock.id}]`}
              defaultValue={details.task?.question || ''}
            />
          </td>
        </tr>
        {
          answers.map((value, index) => <tr key={index}>
            {index === 0 ? <td rowSpan={answers.size}>Svar:</td> : <></>}
            <td>
              <input
                type="text"
                name={`answers[${unlock.id}][]`}
                value={value}
                onChange={(e) => setAnswers(answers.update(index, () => e.target.value))}
              />
              {index > 0 ? <button onClick={() => setAnswers(answers.remove(index))}>❌</button> : <></>}
              {index + 1 >= answers.size ? <button onClick={() => setAnswers(answers.push(''))}>➕</button> : <></>}
            </td>
          </tr>)
        }
      </tbody>
    </table>
    <input type="submit" value="Spara" />
  </form>
}

const GiftCalendarAdmin: React.FC = () => {
  const dispatch = useDispatch()
  const {calendarId} = useParams()
  const [expanded, setExpanded] = useState(false);
  const user = useSelector((state: AppState) => state.user.settings.user)
  const state = useSelector((state: AppState) => state.giftCalendar)

  const calendar = findGiftCalendar(state, calendarId)

  if (!user || user.status.id < 3) return <></>

  if (!expanded)
    return <p><button onClick={() => setExpanded(true)}>Admin</button></p>

  if (!calendar) return <p>Kunde inte hitta kalendern.</p>

  return <>
    <h3>Luckor</h3>
    {
      calendar.unlocks.map((unlock) => <GiftCalendarAdminUnlockRow key={unlock.id} unlock={unlock} />)
    }

    <h3>Ny lucka</h3>
    <form
      onSubmit={(e) => {
        e.preventDefault()
        const form = e.target as HTMLFormElement
        const unlockDate = dayjs(form.elements["unlockDate"].value).toDate()
        dispatch(postGiftCalendarUnlock(calendarId, form.elements['name'].value, unlockDate))
        form.reset()
        return false
      }}
    >
      <table>
        <tbody>
          <tr>
            <td>Namn:</td>
            <td><input type="text" name="name" placeholder="Lucka 1" /></td>
          </tr>
          <tr>
            <td>Tidpunkt:</td>
            <td>
              <input type="datetime-local" name="unlockDate" defaultValue={dayjs().format("YYYY-MM-DDT00:00")} />
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <input type="submit" value="Lägg till" />
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  </>
}


export const GiftCalendarPage: React.FC = () => {
  const {calendarId} = useParams()

  const state = useSelector((state: AppState) => state.giftCalendar)
  const calendar = findGiftCalendar(state, calendarId)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getGiftCalendar(calendarId))
  }, [calendarId])

  if (!calendar) {
    if (state.loading)
      return <Spinner />
    else
      return <strong>Kunde inte ladda kalendern!</strong>
  }

  return <>
    <h1>{calendar.name}</h1>

    <map name={`giftCalendarMap${calendar.id}`}>
      {calendar.unlocks.map((unlock) => {
        const coords = [unlock.pos1.x, unlock.pos1.y, unlock.pos2.x, unlock.pos2.y].join(', ')
        const url = `/gift-calendars/${calendar.id}/unlocks/${unlock.id}`;
        return <area
          key={unlock.id}
          shape="rect"
          coords={coords}
          href={url}
          onClick={(e) => {
            e.preventDefault()

            if (new Date() < unlock.startDate) {
              alert(`${unlock.name} får inte öppnas än!`);
              return false;
            } else {
              return followURL(url);
            }
          }}
          alt={unlock.name}
          title={unlock.name}
        />
      })}
    </map>

    <img
      src={calendar.image}
      alt={calendar.name}
      useMap={state.admin.placingUnlockId == null ? "#giftCalendarMap" + calendar.id : null}
      onClick={(e) => {
        if (state.admin.placingUnlockId) {
          e.preventDefault()

          dispatch(registerGiftCalendarPlacement({
            x: e.pageX - findPosX(e.target),
            y: e.pageY - findPosY(e.target),
          }))

          return false
        }

        return true
      }}
      style={{border: '0px'}}
    />

    <GiftCalendarAdmin />
  </>
}
