import { List } from 'immutable'
import { v4 as uuidv4 } from 'uuid'
import { GiftCalendarUnlockUpdate } from '../services/mugglis'
import {Message, Conversation} from './state/chat'
import { GiftCalendar, GiftCalendarUnlock, GiftCalendarUnlockDetails, Position } from './state/gift_calendar'
import { ItemCode } from './state/item_code'
import {User, Settings} from './state/user'

export const GET_GIFT_CALENDARS = 'GET_GIFT_CALENDARS'
export const GET_GIFT_CALENDARS_SUCCESS = 'GET_GIFT_CALENDARS_SUCCESS'
export const GET_GIFT_CALENDARS_FAIL = 'GET_GIFT_CALENDARS_FAIL'
export const POST_GIFT_CALENDAR = 'POST_GIFT_CALENDAR'
export const POST_GIFT_CALENDAR_SUCCESS = 'POST_GIFT_CALENDAR_SUCCESS'
export const POST_GIFT_CALENDAR_FAIL = 'POST_GIFT_CALENDAR_FAIL'
export const GET_GIFT_CALENDAR = 'GET_GIFT_CALENDAR'
export const GET_GIFT_CALENDAR_SUCCESS = 'GET_GIFT_CALENDAR_SUCCESS'
export const GET_GIFT_CALENDAR_FAIL = 'GET_GIFT_CALENDAR_FAIL'
export const GET_GIFT_CALENDAR_UNLOCK_DETAILS = 'GET_GIFT_CALENDAR_UNLOCK_DETAILS'
export const GET_GIFT_CALENDAR_UNLOCK_DETAILS_SUCCESS = 'GET_GIFT_CALENDAR_UNLOCK_DETAILS_SUCCESS'
export const GET_GIFT_CALENDAR_UNLOCK_DETAILS_FAIL = 'GET_GIFT_CALENDAR_UNLOCK_DETAILS_FAIL'
export const POST_GIFT_CALENDAR_UNLOCK = 'POST_GIFT_CALENDAR_UNLOCK'
export const POST_GIFT_CALENDAR_UNLOCK_SUCCESS = 'POST_GIFT_CALENDAR_UNLOCK_SUCCESS'
export const POST_GIFT_CALENDAR_UNLOCK_FAIL = 'POST_GIFT_CALENDAR_UNLOCK_FAIL'
export const UPDATE_GIFT_CALENDAR_UNLOCK = 'UPDATE_GIFT_CALENDAR_UNLOCK'
export const UPDATE_GIFT_CALENDAR_UNLOCK_SUCCESS = 'UPDATE_GIFT_CALENDAR_UNLOCK_SUCCESS'
export const UPDATE_GIFT_CALENDAR_UNLOCK_FAIL = 'UPDATE_GIFT_CALENDAR_UNLOCK_FAIL'
export const LISTEN_FOR_GIFT_CALENDAR_PLACEMENT = 'LISTEN_FOR_GIFT_CALENDAR_PLACEMENT'
export const REGISTER_GIFT_CALENDAR_PLACEMENT = 'REGISTER_GIFT_CALENDAR_PLACEMENT'
export const POST_GIFT_CALENDAR_UNLOCK_GUESS = 'POST_GIFT_CALENDAR_UNLOCK_GUESS'
export const POST_GIFT_CALENDAR_UNLOCK_GUESS_SUCCESS = 'POST_GIFT_CALENDAR_UNLOCK_GUESS_SUCCESS'
export const POST_GIFT_CALENDAR_UNLOCK_GUESS_FAIL = 'POST_GIFT_CALENDAR_UNLOCK_GUESS_FAIL'
export const GET_ITEM_CODES = 'GET_ITEM_CODES';
export const GET_ITEM_CODES_SUCCESS = 'GET_ITEM_CODES_SUCCESS';
export const GET_ITEM_CODES_FAIL = 'GET_ITEM_CODES_FAIL';
export const POST_ITEM_CODE = 'POST_ITEM_CODE';
export const POST_ITEM_CODE_SUCCESS = 'POST_ITEM_CODE_SUCCESS';
export const POST_ITEM_CODE_FAIL = 'POST_ITEM_CODE_FAIL';
export const PATCH_ITEM_CODE = 'PATCH_ITEM_CODE';
export const PATCH_ITEM_CODE_SUCCESS = 'PATCH_ITEM_CODE_SUCCESS';
export const PATCH_ITEM_CODE_FAIL = 'PATCH_ITEM_CODE_FAIL';
export const POST_WEBSOCKET_TOKEN = 'POST_WEBSOCKET_TOKEN'
export const WEBSOCKET_MESSAGE = 'WEBSOCKET_MESSAGE'
export const GET_USER = 'GET_USER'
export const ON_GET_USERS = 'ON_GET_USERS'
export const GET_CONVERSATIONS = 'GET_CONVERSATIONS'
export const GET_CONVERSATIONS_SUCCESS = 'GET_CONVERSATIONS_SUCCESS'
export const GET_CONVERSATIONS_FAIL = 'GET_CONVERSATIONS_FAIL'
export const GET_CONVERSATION = 'GET_CONVERSATION'
export const GET_CONVERSATION_SUCCESS = 'GET_CONVERSATION_SUCCESS'
export const GET_CONVERSATION_FAIL = 'GET_CONVERSATION_FAIL'
export const GET_CONVERSATION_MESSAGES = 'GET_CONVERSATION_MESSAGES'
export const GET_CONVERSATION_MESSAGES_SUCCESS = 'GET_CONVERSATION_MESSAGES_SUCCESS'
export const GET_CONVERSATION_MESSAGES_FAIL = 'GET_CONVERSATION_MESSAGES_FAIL'
export const GET_SETTINGS = 'GET_SETTINGS'
export const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS'
export const GET_SETTINGS_FAIL = 'GET_SETTINGS_FAIL'
export const SEND_CONVERSATION_MESSAGE = 'SEND_CONVERSATION_MESSAGE'
export const SEND_CONVERSATION_MESSAGE_SUCCESS = 'SEND_CONVERSATION_MESSAGE_SUCCESS'
export const SEND_CONVERSATION_MESSAGE_FAIL = 'SEND_CONVERSATION_MESSAGE_FAIL'
export const MARK_CONVERSATION_READ = 'MARK_CONVERSATION_READ'
export const DELETE_MESSAGE = 'DELETE_MESSAGE'


export interface GetGiftCalendars {
  type: typeof GET_GIFT_CALENDARS,
}

export interface GetGiftCalendarsSuccess {
  type: typeof GET_GIFT_CALENDARS_SUCCESS,
  giftCalendars: List<GiftCalendar>,
}

export interface GetGiftCalendarsFail {
  type: typeof GET_GIFT_CALENDARS_FAIL,
}

export interface PostGiftCalendar {
  type: typeof POST_GIFT_CALENDAR,
  name: string,
  image: File,
  startDate: Date,
  endDate: Date,
}

export interface PostGiftCalendarSuccess {
  type: typeof POST_GIFT_CALENDAR_SUCCESS,
  giftCalendar: GiftCalendar,
}

export interface PostGiftCalendarFail {
  type: typeof POST_GIFT_CALENDAR_FAIL,
}

export interface GetGiftCalendar {
  type: typeof GET_GIFT_CALENDAR,
  calendarId: string,
}

export interface GetGiftCalendarSuccess {
  type: typeof GET_GIFT_CALENDAR_SUCCESS,
  giftCalendar: GiftCalendar,
}

export interface GetGiftCalendarFail {
  type: typeof GET_GIFT_CALENDAR_FAIL,
}

export interface GetGiftCalendarUnlockDetails {
  type: typeof GET_GIFT_CALENDAR_UNLOCK_DETAILS,
  unlockId: string,
}

export interface GetGiftCalendarUnlockDetailsSuccess {
  type: typeof GET_GIFT_CALENDAR_UNLOCK_DETAILS_SUCCESS,
  unlockId: string,
  details: GiftCalendarUnlockDetails,
}

export interface GetGiftCalendarUnlockDetailsFail {
  type: typeof GET_GIFT_CALENDAR_UNLOCK_DETAILS_FAIL,
  unlockId: string,
}

export interface ListenForGiftCalendarPlacement {
  type: typeof LISTEN_FOR_GIFT_CALENDAR_PLACEMENT,
  unlockId: string,
}

export interface RegisterGiftCalendarPlacement {
  type: typeof REGISTER_GIFT_CALENDAR_PLACEMENT,
  position: Position,
}

export interface PostGiftCalendarUnlock {
  type: typeof POST_GIFT_CALENDAR_UNLOCK,
  calendarId: string,
  name: string,
  unlockDate: Date,
}

export interface PostGiftCalendarUnlockSuccess {
  type: typeof POST_GIFT_CALENDAR_UNLOCK_SUCCESS,
  giftCalendarId: string,
  unlock: GiftCalendarUnlock,
  details: GiftCalendarUnlockDetails,
}

export interface PostGiftCalendarUnlockFail {
  type: typeof POST_GIFT_CALENDAR_UNLOCK_FAIL,
}

export interface UpdateGiftCalendarUnlock {
  type: typeof UPDATE_GIFT_CALENDAR_UNLOCK,
  unlockId: string,
  imageFile: File,
  update: GiftCalendarUnlockUpdate,
}

export interface UpdateGiftCalendarUnlockSuccess {
  type: typeof UPDATE_GIFT_CALENDAR_UNLOCK_SUCCESS,
  unlock: GiftCalendarUnlock,
  details: GiftCalendarUnlockDetails,
}

export interface UpdateGiftCalendarUnlockFail {
  type: typeof UPDATE_GIFT_CALENDAR_UNLOCK_FAIL,
  unlockId: string,
}

export interface PostGiftCalendarUnlockGuess {
  type: typeof POST_GIFT_CALENDAR_UNLOCK_GUESS,
  unlockId: string,
  guess: string,
}

export interface PostGiftCalendarUnlockGuessSuccess {
  type: typeof POST_GIFT_CALENDAR_UNLOCK_GUESS_SUCCESS,
  unlockId: string,
  details: GiftCalendarUnlockDetails,
}

export interface PostGiftCalendarUnlockGuessFail {
  type: typeof POST_GIFT_CALENDAR_UNLOCK_GUESS_FAIL,
  unlockId: string,
}

export interface GetItemCodes {
  type: typeof GET_ITEM_CODES,
}

export interface GetItemCodesSuccess {
  type: typeof GET_ITEM_CODES_SUCCESS,
  itemCodes: List<ItemCode>,
}

export interface GetItemCodesFail {
  type: typeof GET_ITEM_CODES_FAIL,
}

export interface PostItemCode {
  type: typeof POST_ITEM_CODE,
  code: string,
  name: string,
  image: File,
}

export interface PostItemCodeSuccess {
  type: typeof POST_ITEM_CODE_SUCCESS,
  itemCode: ItemCode,
}

export interface PostItemCodeFail {
  type: typeof POST_ITEM_CODE_FAIL,
}

export interface PatchItemCode {
  type: typeof PATCH_ITEM_CODE,
  id: string,
  name: string,
  image: File,
}

export interface PatchItemCodeSuccess {
  type: typeof PATCH_ITEM_CODE_SUCCESS,
  itemCode: ItemCode,
}

export interface PatchItemCodeFail {
  type: typeof PATCH_ITEM_CODE_FAIL,
}

export interface PostWebsocketToken {
  type: typeof POST_WEBSOCKET_TOKEN,
  token: string,
}

export interface WebsocketMessage {
  type: typeof WEBSOCKET_MESSAGE,
  message: {
    name: string,
    body: any,
  }
}

export interface GetUser {
  type: typeof GET_USER,
  userId: string,
}

export interface OnGetUsers {
  type: typeof ON_GET_USERS,
  users: User[],
}

export interface GetConversations {
  type: typeof GET_CONVERSATIONS,
  cursor: string,
}

export interface GetConversationsSuccess {
  type: typeof GET_CONVERSATIONS_SUCCESS,
  conversations: [Conversation],
  cursor: string,
}

export interface GetConversationsFail {
  type: typeof GET_CONVERSATIONS_FAIL,
}

export interface GetConversation {
  type: typeof GET_CONVERSATION,
  conversationId: string,
}

export interface GetConversationSuccess {
  type: typeof GET_CONVERSATION_SUCCESS,
  conversation: Conversation,
}

export interface GetConversationFail {
  type: typeof GET_CONVERSATION_FAIL,
  conversationId: string,
}

export interface GetConversationMessages {
  type: typeof GET_CONVERSATION_MESSAGES,
  conversationId: string,
  cursor: string,
  notify: boolean,
}

export interface GetConversationMessagesSuccess {
  type: typeof GET_CONVERSATION_MESSAGES_SUCCESS,
  conversationId: string,
  messages: [Message],
  cursor: string,
}

export interface GetConversationMessagesFail {
  type: typeof GET_CONVERSATION_MESSAGES_FAIL,
  conversationId: string,
}

export interface GetSettings {
  type: typeof GET_SETTINGS,
}

export interface GetSettingsSuccess {
  type: typeof GET_SETTINGS_SUCCESS,
  settings: Settings,
}

export interface GetSettingsFail {
  type: typeof GET_SETTINGS_FAIL,
}

export interface SendConversationMessage {
  type: typeof SEND_CONVERSATION_MESSAGE,
  conversationId: string,
  localId: string,
  user: User,
  text: string,
  date: Date,
}

export interface SendConversationMessageSuccess {
  type: typeof SEND_CONVERSATION_MESSAGE_SUCCESS,
  conversationId: string,
  message: Message,
}

export interface SendConversationMessageFail {
  type: typeof SEND_CONVERSATION_MESSAGE_FAIL,
  conversationId: string,
  localId: string,
}

export interface MarkConversationRead {
  type: typeof MARK_CONVERSATION_READ,
  conversationId: string,
  lastMessageReadId: string,
}

export interface DeleteMessage {
  type: typeof DELETE_MESSAGE,
  messageId: string,
}

export const getGiftCalendars = (): GetGiftCalendars => {
  return {
    type: GET_GIFT_CALENDARS,
  }
}

export const getGiftCalendarsSuccess = (calendars: List<GiftCalendar>): GetGiftCalendarsSuccess => {
  return {
    type: GET_GIFT_CALENDARS_SUCCESS,
    giftCalendars: calendars,
  }
}

export const getGiftCalendarsFail = (): GetGiftCalendarsFail => {
  return {
    type: GET_GIFT_CALENDARS_FAIL,
  }
}

export const postGiftCalendars = (name: string, image: File, startDate: Date, endDate: Date): PostGiftCalendar => {
  return {
    type: POST_GIFT_CALENDAR,
    name,
    image,
    startDate,
    endDate
  }
}

export const postGiftCalendarSuccess = (calendar: GiftCalendar): PostGiftCalendarSuccess => {
  return {
    type: POST_GIFT_CALENDAR_SUCCESS,
    giftCalendar: calendar,
  }
}

export const postGiftCalendarFail = (): PostGiftCalendarFail => {
  return {
    type: POST_GIFT_CALENDAR_FAIL,
  }
}

export const getGiftCalendar = (id: string): GetGiftCalendar => {
  return {
    type: GET_GIFT_CALENDAR,
    calendarId: id,
  }
}

export const getGiftCalendarSuccess = (calendar: GiftCalendar): GetGiftCalendarSuccess => {
  return {
    type: GET_GIFT_CALENDAR_SUCCESS,
    giftCalendar: calendar,
  }
}

export const getGiftCalendarFail = (): GetGiftCalendarFail => {
  return {
    type: GET_GIFT_CALENDAR_FAIL,
  }
}

export const getGiftCalendarUnlockDetails = (unlockId: string): GetGiftCalendarUnlockDetails => {
  return {
    type: GET_GIFT_CALENDAR_UNLOCK_DETAILS,
    unlockId: unlockId,
  }
}

export const getGiftCalendarUnlockDetailsSuccess = (unlockId: string, details: GiftCalendarUnlockDetails): GetGiftCalendarUnlockDetailsSuccess => {
  return {
    type: GET_GIFT_CALENDAR_UNLOCK_DETAILS_SUCCESS,
    unlockId: unlockId,
    details: details,
  }
}

export const getGiftCalendarUnlockDetailsFail = (unlockId: string): GetGiftCalendarUnlockDetailsFail => {
  return {
    type: GET_GIFT_CALENDAR_UNLOCK_DETAILS_FAIL,
    unlockId: unlockId,
  }
}

export const postGiftCalendarUnlock = (calendarId: string, name: string, unlockDate: Date): PostGiftCalendarUnlock => {
  return {
    type: POST_GIFT_CALENDAR_UNLOCK,
    calendarId: calendarId,
    name: name,
    unlockDate: unlockDate,
  }
}

export const postGiftCalendarUnlockSuccess = (giftCalendarId: string, unlock: GiftCalendarUnlock, details: GiftCalendarUnlockDetails): PostGiftCalendarUnlockSuccess => {
  return {
    type: POST_GIFT_CALENDAR_UNLOCK_SUCCESS,
    giftCalendarId,
    unlock,
    details,
  }
}

export const postGiftCalendarUnlockFail = (): PostGiftCalendarUnlockFail => {
  return {
    type: POST_GIFT_CALENDAR_UNLOCK_FAIL,
  }
}

export const updateGiftCalendarUnlock = (unlockId: string, imageFile: File, update: GiftCalendarUnlockUpdate): UpdateGiftCalendarUnlock => {
  return {
    type: UPDATE_GIFT_CALENDAR_UNLOCK,
    unlockId: unlockId,
    imageFile: imageFile,
    update: update,
  }
}

export const updateGiftCalendarUnlockSuccess = (unlock: GiftCalendarUnlock, details: GiftCalendarUnlockDetails): UpdateGiftCalendarUnlockSuccess => {
  return {
    type: UPDATE_GIFT_CALENDAR_UNLOCK_SUCCESS,
    unlock: unlock,
    details: details,
  }
}

export const updateGiftCalendarUnlockFail = (unlockId: string): UpdateGiftCalendarUnlockFail => {
  return {
    type: UPDATE_GIFT_CALENDAR_UNLOCK_FAIL,
    unlockId: unlockId,
  }
}

export const postGiftCalendarUnlockGuess = (unlockId: string, guess: string): PostGiftCalendarUnlockGuess => {
  return {
    type: POST_GIFT_CALENDAR_UNLOCK_GUESS,
    unlockId: unlockId,
    guess: guess,
  }
}

export const postGiftCalendarUnlockGuessSuccess = (unlockId: string, details: GiftCalendarUnlockDetails): PostGiftCalendarUnlockGuessSuccess => {
  return {
    type: POST_GIFT_CALENDAR_UNLOCK_GUESS_SUCCESS,
    unlockId: unlockId,
    details: details,
  }
}

export const postGiftCalendarUnlockGuessFail = (unlockId: string): PostGiftCalendarUnlockGuessFail => {
  return {
    type: POST_GIFT_CALENDAR_UNLOCK_GUESS_FAIL,
    unlockId: unlockId,
  }
}

export const listenForGiftCalendarPlacement = (unlockId: string): ListenForGiftCalendarPlacement => {
  return {
    type: LISTEN_FOR_GIFT_CALENDAR_PLACEMENT,
    unlockId: unlockId,
  }
}

export const registerGiftCalendarPlacement = (position: Position): RegisterGiftCalendarPlacement => {
  return {
    type: REGISTER_GIFT_CALENDAR_PLACEMENT,
    position: position,
  }
}

export const getItemCodes = (): GetItemCodes => {
  return {
    type: GET_ITEM_CODES,
  }
}

export const getItemCodesSuccess = (itemCodes: List<ItemCode>): GetItemCodesSuccess => {
  return {
    type: GET_ITEM_CODES_SUCCESS,
    itemCodes: itemCodes,
  }
}

export const getItemCodesFail = (): GetItemCodesFail => {
  return {
    type: GET_ITEM_CODES_FAIL,
  }
}

export const postItemCode = (code: string, name: string, image: File): PostItemCode => {
  return {
    type: POST_ITEM_CODE,
    code: code,
    name: name,
    image: image,
  }
}

export const postItemCodeSuccess = (itemCode: ItemCode): PostItemCodeSuccess => {
  return {
    type: POST_ITEM_CODE_SUCCESS,
    itemCode: itemCode,
  }
}

export const postItemCodeFail = (): PostItemCodeFail => {
  return {
    type: POST_ITEM_CODE_FAIL,
  }
}

export const patchItemCode = (id: string, name: string, image: File): PatchItemCode => {
  return {
    type: PATCH_ITEM_CODE,
    id: id,
    name: name,
    image: image,
  }
}

export const patchItemCodeSuccess = (itemCode: ItemCode): PatchItemCodeSuccess => {
  return {
    type: PATCH_ITEM_CODE_SUCCESS,
    itemCode: itemCode,
  }
}

export const patchItemCodeFail = (): PatchItemCodeFail => {
  return {
    type: PATCH_ITEM_CODE_FAIL,
  }
}

export const postWebsocketToken = (token: string): PostWebsocketToken => {
  return {
    type: POST_WEBSOCKET_TOKEN,
    token: token,
  }
}

export const websocketMessage = (message: {name: string, body: any}): WebsocketMessage => {
  return {
    type: WEBSOCKET_MESSAGE,
    message: message,
  }
}

export const getUser = (userId: string): GetUser => {
  return {
    type: GET_USER,
    userId: userId,
  }
}

export const onGetUsers = (users: User[]): OnGetUsers => {
  return {
    type: ON_GET_USERS,
    users: users,
  }
}

export const getConversations = (cursor: string): GetConversations => {
  return {
    type: GET_CONVERSATIONS,
    cursor: cursor,
  }
}

export const getConversationsSuccess = (conversations: [Conversation], cursor: string): GetConversationsSuccess => {
  return {
    type: GET_CONVERSATIONS_SUCCESS,
    conversations: conversations,
    cursor: cursor,
  }
}

export const getConversationsFail = (): GetConversationsFail => {
  return {
    type: GET_CONVERSATIONS_FAIL,
  }
}

export const getConversation = (conversationId: string): GetConversation => {
  return {
    type: GET_CONVERSATION,
    conversationId: conversationId,
  }
}

export const getConversationSuccess = (conversation: Conversation): GetConversationSuccess => {
  return {
    type: GET_CONVERSATION_SUCCESS,
    conversation: conversation,
  }
}

export const getConversationFail = (conversationId: string): GetConversationFail => {
  return {
    type: GET_CONVERSATION_FAIL,
    conversationId: conversationId,
  }
}

export const getConversationMessages = (
  {conversationId, cursor=null, notify=false}: {conversationId: string, cursor?: string, notify?: boolean}
): GetConversationMessages => {
  return {
    type: GET_CONVERSATION_MESSAGES,
    conversationId: conversationId,
    cursor: cursor,
    notify: notify,
  }
}

export const getConversationMessagesSuccess = (conversationId: string, messages: [Message], cursor: string): GetConversationMessagesSuccess => {
  return {
    type: GET_CONVERSATION_MESSAGES_SUCCESS,
    conversationId: conversationId,
    messages: messages,
    cursor: cursor,
  }
}

export const getConversationMessagesFail = (conversationId: string): GetConversationMessagesFail => {
  return {
    type: GET_CONVERSATION_MESSAGES_FAIL,
    conversationId: conversationId,
  }
}

export const getSettings = (): GetSettings => {
  return {
    type: GET_SETTINGS,
  }
}

export const getSettingsSuccess = (settings: Settings): GetSettingsSuccess => {
  return {
    type: GET_SETTINGS_SUCCESS,
    settings: settings,
  }
}

export const getSettingsFail = (): GetSettingsFail => {
  return {
    type: GET_SETTINGS_FAIL,
  }
}

export const sendConversationMessage = (conversationiId: string, text: string, user: User, localId?: string): SendConversationMessage => {
  return {
    type: SEND_CONVERSATION_MESSAGE,
    conversationId: conversationiId,
    user: user,
    text: text,
    localId: localId || uuidv4(),
    date: new Date(),
  }
}

export const sendConversationMessageSuccess = (conversationId: string, message: Message): SendConversationMessageSuccess => {
  return {
    type: SEND_CONVERSATION_MESSAGE_SUCCESS,
    conversationId: conversationId,
    message: message,
  }
}

export const sendConversationMessageFail = (conversationId: string, localId: string): SendConversationMessageFail => {
  return {
    type: SEND_CONVERSATION_MESSAGE_FAIL,
    conversationId: conversationId,
    localId: localId,
  }
}

export const markConversationRead = (conversationId: string, lastMessageReadId: string): MarkConversationRead => {
  return {
    type: MARK_CONVERSATION_READ,
    conversationId: conversationId,
    lastMessageReadId: lastMessageReadId,
  }
}

export const deleteMessage = (messageId: string): DeleteMessage => {
  return {
    type: DELETE_MESSAGE,
    messageId: messageId,
  }
}
