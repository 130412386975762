import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {useParams} from 'react-router-dom'
import { getGiftCalendar, getGiftCalendarUnlockDetails, postGiftCalendarUnlockGuess } from "../redux/actions"
import { findGiftCalendar, findGiftCalendarUnlockDetails, GiftCalendar, GiftCalendarUnlock, GiftCalendarUnlockDetails } from "../redux/state/gift_calendar"
import { AuthRequired } from "./utils/AuthRequired"
import { AppState } from "../redux/reducers"
import {Spinner} from './utils/Spinner'
import { followURL } from "../utils"
import { LocalLink } from "./utils/LocalLink"
import { Money } from "./utils/Money"
import { ItemView } from "./utils/Item"


const findUnlock = (calendar: GiftCalendar, unlockId: string) => {
  for (let unlock of calendar.unlocks)
    if (unlock.id == unlockId)
      return unlock
}


interface GiftCalendarTaskResultProps {
  unlock: GiftCalendarUnlock,
  details: GiftCalendarUnlockDetails,
}

const GiftCalendarTaskResult: React.FC<GiftCalendarTaskResultProps> = ({unlock, details}) => {
  const dispatch = useDispatch()
  const [hasTried, setHasTried] = useState(false)

  if (details.progress.correct) {
    return <>
    <p>Du svarade rätt på den här frågan!</p>
    <h4>Ditt pris:</h4>
    <div style={{textAlign: 'center'}}>
      {details.progress.rewards.map((reward, i) => {
        if (reward.type === 'money') {
          return <React.Fragment key={i}>
            <img src='images/money.png' alt='Pengar' style={{verticalAlign: 'middle'}} />
            <Money amount={reward.amount} />
          </React.Fragment>
        } else if (reward.type === 'items') {
          return <React.Fragment key={i}>
            {reward.items.map((item, j) => {
              return <ItemView key={j} item={item} />
            })}
          </React.Fragment>
        } else {
          return <React.Fragment key={i}>Okänt?</React.Fragment>
        }
      })}
    </div>
  </>
/*
{foreach from=$prices item=price key=type}
  {if $type == "items"}
    {section name=x loop=$price}
      {if $price[x].type == "card"}
        {assign var=card value=$price[x].item}
        {include file="card.tpl"}
      {else}
        {assign var=item value=$price[x].item}
        {include file=$price[x].item->template}
      {/if}
    {/section}
  {elseif $type == "money"}
    <img src="images/money.png" alt="Pengar" style="vertical-align: middle;" />
    <strong>{$price.string}!</strong>
  {/if}
{/foreach}

    {if $day == 24}<span style="font-size: x-large; font-weight: bold;">God jul och gott nytt år!</span>{/if}

    */
  } else {
    if (details.progress.nTries < details.task.maxTries) {
        
       return <>
        <form onSubmit={(e) => {
          e.preventDefault()

          const guess = (e.target as HTMLFormElement).querySelector('input[name=guess]') as HTMLInputElement

          if (guess.value.length > 0) {
            dispatch(postGiftCalendarUnlockGuess(unlock.id, guess.value))
            guess.value = ''
            setHasTried(true)
          }
        }}>
          <input
            type="text"
            name="guess"
            size={50}
            autoFocus={true}
            onChange={() => setHasTried(false)}
          />
          <input type="submit" value="Svara" disabled={details.progress.loading} />
        </form>
        <p>Du har gissat {details.progress.nTries} av {details.task.maxTries} gånger!</p>

        {hasTried && !details.progress.loading ? <p><strong>Det var tyvärr inte rätt svar!</strong></p> : ''}
      </>
    } else {
      // TODO: give a hint to answer again tomorrow, if this is not the final unlock
      return <p>Du har redan gissat tre gånger på den här frågan!</p>
    }
  }
}


interface GiftCalendarUnlockProps {
  unlock: GiftCalendarUnlock,
}


const GiftCalendarUnlockView: React.FC<GiftCalendarUnlockProps> = ({unlock}) => {
  const dispatch = useDispatch()

  const state = useSelector((state: AppState) => state.giftCalendar)

  const details = findGiftCalendarUnlockDetails(state, unlock.id)

  useEffect(() => {
    dispatch(getGiftCalendarUnlockDetails(unlock.id))
  }, [unlock.id])

  if (!details || details.loading) {
    if (details)
      return <Spinner />
    else
      return <p>Kunde inte ladda luckan!</p>
  }

  return <>
    <img src={details.task.image} alt={unlock.name} />
    <h3>{details.task.question}</h3>

    <GiftCalendarTaskResult unlock={unlock} details={details} />
  {/*
    {if $finished}
    <p>Du svarade rätt på den här frågan!</p>

    <h4>Ditt pris:</h4>
    <p style="text-align: center;">
{foreach from=$prices item=price key=type}
  {if $type == "items"}
    {section name=x loop=$price}
      {if $price[x].type == "card"}
        {assign var=card value=$price[x].item}
        {include file="card.tpl"}
      {else}
        {assign var=item value=$price[x].item}
        {include file=$price[x].item->template}
      {/if}
    {/section}
  {elseif $type == "money"}
    <img src="images/money.png" alt="Pengar" style="vertical-align: middle;" />
    <strong>{$price.string}!</strong>
  {/if}
{/foreach}
      </p>

    {if $day == 24}<span style="font-size: x-large; font-weight: bold;">God jul och gott nytt år!</span>{/if}

    {else}
      {if $guesses < 3}
        <form method="post" action="jul{$year}.php?day={$day}" onsubmit="return sendForm(this);">
          <input type="text" name="answer" size="50" />
          <input type="submit" value="Svara" />
        </form>

        <p>Du har gissat {$guesses} av 3 gånger!</p>
      {else}
        <p>Du har redan gissat tre gånger på den här frågan!{if $day < 24} Försök igen i morgondagens lucka i stället!{/if}</p>
      {/if}
      {if $guessed}
        <p><strong>Det var tyvärr inte rätt svar!</strong></p>
      {/if}
    {/if}
    */}
  </>
}


export const GiftCalendarUnlockPage: React.FC = () => {
  const {calendarId, unlockId} = useParams()

  const state = useSelector((state: AppState) => state.giftCalendar)
  const calendar = findGiftCalendar(state, calendarId)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getGiftCalendar(calendarId))
  }, [calendarId])

  if (!calendar) {
    if (state.loading)
      return <Spinner />
    else
      return <strong>Kunde inte ladda kalendern!</strong>
  }

  const unlock = findUnlock(calendar, unlockId)
  const parentUrl = `gift-calendars/${calendarId}`;

  if (!unlock)
    followURL(parentUrl)

  return <>
    <h1>{calendar.name}</h1>

    <p><LocalLink url={parentUrl}>Tillbaka...</LocalLink></p>

    <div style={{textAlign: 'center'}}>
      <h2>{unlock.name}</h2>
      <AuthRequired error={<p>Du måste vara <LocalLink url={'login.php'}>inloggad</LocalLink> för att öppna luckorna!</p>}>
        {
          new Date() < unlock.startDate ? <p>Den här luckan är inte öppen än!</p> :
          <GiftCalendarUnlockView unlock={unlock} />
        }

      </AuthRequired>
    </div>
  </>
}
