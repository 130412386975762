import React from 'react'
import {Link} from './Link'
import {User} from '../../redux/state/user'

interface UserLinkProps {
  user: User,
}

export const UserLink: React.FC<UserLinkProps> = ({user}) => {
  if (user.deleted)
    return <i>Borttagen</i>

  if (!user.id)
    return <span>{user.name}</span>

  const color: string = user.team ? user.team.color : null;
  return <Link url={`user.php?id=${user.id}`} color={color}>{user.name}</Link>
}
