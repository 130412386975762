
export const findPosX = obj => {
  let curleft = 0
  if (obj.offsetParent)
    while (true) {
      curleft += obj.offsetLeft
      if (!obj.offsetParent)
        break
      obj = obj.offsetParent
    }
  else if (obj.x)
    curleft += obj.x
  return curleft
}

export const findPosY = obj => {
  let curtop = 0
  if (obj.offsetParent)
    while (true) {
      curtop += obj.offsetTop
      if (!obj.offsetParent)
        break
      obj = obj.offsetParent
    }
  else if (obj.y)
    curtop += obj.y
  return curtop;
}

export const refreshPronouns = () => {
  const preview = document.getElementById("pronounPreview") as HTMLSpanElement
  const sexFemale = document.getElementById("sexFemale") as HTMLInputElement
  const sexMale = document.getElementById("sexMale") as HTMLInputElement
  const sexNA = document.getElementById("sexNA") as HTMLInputElement
  const pronounDetails = document.getElementById("pronounDetails") as HTMLTableElement
  const pronounSubjective = document.getElementById("pronounSubjective") as HTMLInputElement
  const pronounObjective = document.getElementById("pronounObjective") as HTMLInputElement
  const pronounPossessive = document.getElementById("pronounPossessive") as HTMLInputElement

  if (sexFemale.checked) {
    preview.innerHTML = "hon/henne/hennes"
    pronounDetails.style.display = "none"
  } else if (sexMale.checked) {
    preview.innerHTML = "han/honom/hans"
    pronounDetails.style.display = "none"
  } else if (sexNA.checked) {
    preview.innerHTML = [pronounSubjective.value, pronounObjective.value, pronounPossessive.value].filter((v) => v).join("/")
    pronounDetails.style.display = "block"
  }
}
