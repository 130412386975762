import {Howl} from 'howler'
import { List } from 'immutable';
import {follow} from './modules/ajax'


export const getCookies = () => {
  return document.cookie.split(';').map(function(c) {
    return c.trim().split('=').map(decodeURIComponent);
  }).reduce(function(a, b) {
    try {
      a[b[0]] = JSON.parse(b[1]);
    } catch (e) {
      a[b[0]] = b[1];
    }
    return a;
  }, {});
}


export const deduplicate = <T>(list: T[], comparator: Function): T[] => {
  const result = <T[]>[]

  for (let item1 of list) {
    if (result.filter((item2) => comparator(item1, item2)).length <= 0)
      result.push(item1)
  }

  return result
}


export const deduplicateList = <T>(list: List<T>, comparator: Function): List<T> => {
  const result = deduplicate(list.toJS(), comparator)
  return List<T>(result)
}

export const playSound = (name: string) => {
  const webm = require(`./sounds/${name}.webm`).default
  const mp3 = require(`./sounds/${name}.mp3`).default

  const sound = new Howl({src: [webm, mp3]})
  sound.play()
}

export const setUnion = <T>(setA: Set<T>, setB: Set<T>): Set<T> => {
  const _union = new Set(setA)
  for (const elem of setB) {
    _union.add(elem)
  }
  return _union
}

export const setDifference = <T>(setA: Set<T>, setB: Set<T>): Set<T> => {
    const _difference = new Set(setA)
    for (const elem of setB) {
        _difference.delete(elem)
    }
    return _difference
}

export const followURL = (url: string) => {
  const a = document.createElement('a')
  a.href = url
  return follow(a)
}
