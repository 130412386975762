import React, { CSSProperties, useState } from 'react'
import { CardItem, Item } from '../../redux/state/gift_calendar'


interface CardViewProps {
  card: CardItem,
}

const CardView: React.FC<CardViewProps> = ({card}) => {
  const [flipped, setFlipped] = useState(false)

  const style: CSSProperties = {
    margin: '1px',
  }

  if (card.color !== null) {
    style.backgroundImage = `images/card_${card.color}.png`
  } else {
    if (card.rarity === 1) {
      style.backgroundImage = 'images/card_gold.png'
    } else if (card.rarity == 2) {
      style.backgroundImage = 'images/card_silver.png'
    }
  }

  return <div
    className="card"
    style={style}
    onMouseOver={() => setFlipped(true)}
    onMouseOut={() => setFlipped(false)}
  >
    <img className="card_image" src={card.image} alt="{$card->name}" style={{display: flipped ? 'none' : 'inline'}} />

    <br />

    <span style={{margin: '5px', display: flipped ? 'none' : 'inline'}} id="name{$card->id}"><b>{card.name}</b></span>

    {
      (!card.birthYear && !card.deathYear) ? '' :
      <p style={{display: flipped ? 'none' : 'block'}}>{card.birthYear} - {card.deathYear}</p>
    }

    <p className="card_description" style={{display: flipped ? 'block' : 'none'}}>{card.description}</p>

    {/* TODO: something we need?
    {if $card->quantity}
      <span class="card_quantity">x{$card->quantity}</span>
    {/if}
    */}
  </div>
}


export interface ItemViewProps {
  item: Item,
}

export const ItemView: React.FC<ItemViewProps> = ({item}) => {
  if (item.type === "card") {
    return <CardView card={item.card} />
  } else if(item.type == 'wand') { // Handle wands
    throw "TODO: Implement wand";
  } else if (item.type == "dragon") { // Handle dragons
    throw "TODO: Implement dragon";
  } else if (item.type == "dragon_blood") { // Handle dragon blood
    throw "TODO: Implement dragon_blood";
  } else if (item.type == "animal") { // Handle animals
    throw "TODO: Implement animal";
  } else if (item.type == "christmas_pug") {
    throw "TODO: Implement christmas_pug";
  } else if (item.type == "christmas_pug2") {
    throw "TODO: Implement christmas_pug2";
  } else if (item.type == "weasley_shirt") {
    throw "TODO: Implement weasley_shirt";
  } else if (item.type === "quidditch_bat") {
    return <img
      src={item.quidditchBat.image}
      title={item.quidditchBat.name}
      alt={item.quidditchBat.name}
    />
  } else if (item.type === "generic") {
    return <img
      src={item.generic.image}
      title={item.generic.name}
      alt={item.generic.name}
      style={item.generic.scale ? {width: `width: ${item.generic.scale}%`} : null}
    />
  } else {
    return <>Okänt?</>
  }
}
