import React, { useState } from 'react'


interface ExpandablePanelProps {
  text: string,
  children: React.ReactNode,
}

export const ExpandablePanel: React.FC<ExpandablePanelProps> = ({text, children}) => {
  const [expanded, setExpanded] = useState(false)

  if (expanded)
    return <>{children}</>
  else
    return <input type="button" value={text} onClick={() => setExpanded(true)} />
}
