import React, {useState, useEffect, CSSProperties} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useParams} from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroller'
import {ChatState} from '../redux/state/chat'
import {BBCode} from './utils/BBCode'
import {FormattedDate} from './utils/FormattedDate'
import {Link} from './utils/Link'
import {Spinner} from './utils/Spinner'
import {UserLink} from './utils/UserLink'
import {getConversation, getConversationMessages, sendConversationMessage, markConversationRead, deleteMessage} from '../redux/actions'
import {findConversation} from '../redux/state/chat'
import {AppState} from '../redux/reducers'
import {getCookies} from '../utils'
import { BBCodePanel } from './utils/BBCodePanel'
import { ExpandablePanel } from './utils/ExpandablePanel'
import {User} from '../redux/state/user'
import {Conversation, Message} from '../redux/state/chat'


interface UserCellProps {
  cellClass: string,
  isYourMessage: boolean,
  user: User,
}


const UserCell: React.FC<UserCellProps> = ({cellClass, isYourMessage, user}) => {
  const style: CSSProperties = {
    borderTop: '1px solid black',
    textAlign: 'center'
  }

  if (isYourMessage)
    style.borderRight = '1px solid black'
  else
    style.borderLeft = '1px solid black'

  return <td className={cellClass} style={style}>
    {
    user.avatar ?
    <Link url={`/user.php?id=${user.id}`}>
      <img src={user.avatar.href} alt={user.name} title={user.name} style={{width: 32, border: '1px solid black'}} />
    </Link>
    :
    ''
    }
  </td>
}


interface DeleteButtonProps {
  messageId: string,
}

const DeleteButton: React.FC<DeleteButtonProps> = ({messageId}) => {
  const dispatch = useDispatch()

  return <a
    href=""
    onClick={(e) => {
      e.preventDefault()

      if (confirm('Är du säker på att du vill ta bort det här inlägget? (Den andra personen kan fortfarande se det)')) {
        dispatch(deleteMessage(messageId));
      }
    }}
  >✖</a>
}


interface MessageRowProps {
  conversation: Conversation,
  message: Message,
}

const MessageRow: React.FC<MessageRowProps> = ({conversation, message}) => {
  const dispatch = useDispatch()
  const settings = useSelector((state: AppState) => state.user.settings)
  const cookies = getCookies()
  const youId = cookies['user_id'].toString()

  const isYourMessage = message.user.id === youId
  const read = message.read || isYourMessage
  const cellClass = read ? '' : 'highlight'

  const cellStyle: CSSProperties = {
    borderTop: '1px solid black',
  }

  if (isYourMessage) {
    cellStyle.paddingLeft = 20
  } else {
    cellStyle.paddingRight = 20
  }

  const noLeftCell = <td className={cellClass} style={{backgroundColor: 'transparent', border: 0, borderRight: '1px solid black'}}></td>
  const noRightCell = <td className={cellClass} style={{backgroundColor: 'transparent', border: 0, borderLeft: '1px solid black'}}></td>
  const emptyCell = <td style={{backgroundColor: 'transparent', border: 0}}></td>
  const emptyRow = <tr>{emptyCell}{emptyCell}{emptyCell}{emptyCell}</tr>

  const formattedDate = <span style={{textAlign: isYourMessage ? 'left' : 'right'}}>
    <FormattedDate date={message.date} />
  </span>

  return <>
    {
      isYourMessage ?
      <tr>
        <td style={{backgroundColor: 'transparent', border: 0}}></td>
        <th style={{borderLeft: '1px solid black', borderTop: '1px solid black'}}>
          {formattedDate}
        </th>
        <th style={{
          borderRight: '1px solid black',
          borderTop: '1px solid black',
          textAlign: 'center',
        }}>
          <UserLink user={message.user} />
          <DeleteButton messageId={message.id} />
        </th>
      </tr>
      :
      <tr>
        <th style={{
          borderLeft: '1px solid black',
          borderTop: '1px solid black',
          textAlign: 'center',
        }}>
          <UserLink user={message.user} />
        </th>
        <th style={{
          borderRight: '1px solid black',
          borderTop: '1px solid black',
          textAlign: 'right',
        }}>
          {formattedDate}
        </th>
        <td style={{backgroundColor: 'transparent', border: 0}}></td>
      </tr>
    }
    <tr>
      {
        isYourMessage ? noLeftCell :
        <UserCell cellClass={cellClass} user={message.user} isYourMessage={isYourMessage} />
      }
      <td className={cellClass} style={cellStyle}>
        <BBCode text={message.text} options={{
          html: message.user.system,
          smileys: settings.showSmileys,
        }} />
        {
          message.localId && message.failed ?
          <>
            <br />
            <strong>
              <em>
                Kunde inte skicka, <a onClick={() => dispatch(sendConversationMessage(conversation.id, message.text, message.user, message.localId))}>testa igen</a>
              </em>
            </strong>
          </>
          :
          ''
        }
      </td>
      {
        isYourMessage ?
        <UserCell cellClass={cellClass} user={message.user} isYourMessage={isYourMessage} />
        :
        noRightCell
      }
    </tr>
    {emptyRow}
    {emptyRow}
  </>
}


interface MessageListProps {
  conversationId: string,
}


const MessageList: React.FC<MessageListProps> = ({conversationId}) => {
  const dispatch = useDispatch()
  const chat = useSelector((state: AppState) => state.chat)
  const conversation = findConversation(chat, conversationId)

  const cookies = getCookies()
  const youId = cookies['user_id'].toString()

  useEffect(() => {
    dispatch(getConversationMessages({conversationId}))
  }, [])

  const lastUnreadMessage = conversation.messages.find(m => m.user.id != youId && !m.read)

  useEffect(() => {
    if (lastUnreadMessage)
      dispatch(markConversationRead(conversationId, lastUnreadMessage.id))

  }, [lastUnreadMessage])

  if (!conversation)
    return <Spinner />

  return <>
    <table style={{width: '100%', border: 0, tableLayout: 'auto'}}>
      <InfiniteScroll
        element='tbody'
        loadMore={() => {
          dispatch(getConversationMessages({conversationId, cursor: conversation.cursor}))
        }}
        hasMore={conversation.cursor !== null && !conversation.loading}
      >
        {conversation.messages.map(m => <MessageRow
          key={m.id || m.localId}
          conversation={conversation}
          message={m}
        />)}
      </InfiniteScroll>
    </table>
    {conversation.loading ? <Spinner /> : ''}
  </>
}

interface SendMessageFormProps {
  conversationId: string,
}

export const SendMessageForm: React.FC<SendMessageFormProps> = ({conversationId}) => {
  const settings = useSelector((state: AppState) => state.user.settings)
  const dispatch = useDispatch()

  const [message, setMessage] = useState('')
  const [selection, setSelection] = useState({start: 0, end: 0})

  const sendMessage = () => {
    if (message.length <= 0)
      return

    dispatch(sendConversationMessage(conversationId, message, settings.user))

    setMessage('')
    setSelection({start: 0, end: 0})
  }

  return <>
    <form onSubmit={(e) => {
      e.preventDefault()
      sendMessage()
    }}>
      <p style={{float: 'left'}}>
        <textarea
          cols={40} rows={5}
          value={message}
          onSelect={e => {
            setSelection({
              start: (e.target as any).selectionStart,
              end: (e.target as any).selectionEnd,
            })
          }}
          onChange={e => {
            setMessage(e.target.value)
          }}
        />
        <br />
        <input type='submit' value='Skicka' />
      </p>
    </form>
    <div style={{float: 'left', marginLeft: 10}}>
      <ExpandablePanel text='Formatera...'>
        <BBCodePanel
          value={message}
          start={selection.start}
          end={selection.end}
          setValue={(message: string) => setMessage(message)}
        />
      </ExpandablePanel>
    </div>
  </>
  
}


interface ConversationPageProps {
  chat: ChatState,
  getConversation: Function,
}

export const ConversationPage: React.FC<ConversationPageProps> = () => {
  const {conversationId} = useParams()

  const chat = useSelector((state: AppState) => state.chat)
  const conversation = findConversation(chat, conversationId)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getConversation(conversationId))
  }, [conversationId])

  if (chat.failedConversationIds.has(conversationId))
    return <strong>Kunde inte hämta konversationen</strong>

  if (!conversation)
    return <Spinner />

  return <>
    <h1>{conversation.user.name}</h1>
    <p><Link url='/conversations'>Tillbaka...</Link></p>
    {
      conversation.user.system ? '' :
      <SendMessageForm conversationId={conversationId} />
    }
    <div style={{clear: 'both'}}>
      <MessageList conversationId={conversationId} />
    </div>
  </>
}
