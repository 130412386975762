import * as React from 'react';
import * as ReactDOM from 'react-dom'

import {App} from './App';

export const Ajax = require('./modules/ajax')
export const Hangman = require('./modules/hangman')
export const BBCode = require('./modules/bbcode')
export const Scripts = require('./modules/scripts')


export function renderContent(elementId) {
    ReactDOM.render(<App />, document.getElementById(elementId))
}
