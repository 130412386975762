import { List, Map } from 'immutable'
import { deduplicateList } from "../../utils"
import { GetGiftCalendarUnlockDetailsSuccess, GET_GIFT_CALENDAR, GET_GIFT_CALENDARS_SUCCESS, GET_GIFT_CALENDAR_FAIL, GET_GIFT_CALENDAR_SUCCESS, GET_GIFT_CALENDAR_UNLOCK_DETAILS, GET_GIFT_CALENDAR_UNLOCK_DETAILS_FAIL, GET_GIFT_CALENDAR_UNLOCK_DETAILS_SUCCESS, LISTEN_FOR_GIFT_CALENDAR_PLACEMENT, PostGiftCalendarUnlockGuess, PostGiftCalendarUnlockGuessFail, PostGiftCalendarUnlockGuessSuccess, PostGiftCalendarUnlockSuccess, POST_GIFT_CALENDAR_SUCCESS, POST_GIFT_CALENDAR_UNLOCK_GUESS, POST_GIFT_CALENDAR_UNLOCK_GUESS_FAIL, POST_GIFT_CALENDAR_UNLOCK_GUESS_SUCCESS, POST_GIFT_CALENDAR_UNLOCK_SUCCESS, REGISTER_GIFT_CALENDAR_PLACEMENT, UpdateGiftCalendarUnlockSuccess, UPDATE_GIFT_CALENDAR_UNLOCK_SUCCESS } from "../actions"
import {ObjectCopier} from './base'

export interface Position {
  x: number,
  y: number,
}

export class GiftCalendarUnlock extends ObjectCopier {
  constructor(
    public readonly id: string,
    public readonly name: string,
    public readonly pos1: Position,
    public readonly pos2: Position,
    public readonly startDate: Date,
  ) {
    super();
  }
}

export class GiftCalendar extends ObjectCopier {
  constructor(
    public readonly id: string,
    public readonly name: string,
    public readonly startDate: Date,
    public readonly endDate: Date,
    public readonly image: string,
    public readonly unlocks: List<GiftCalendarUnlock>
  ) {
    super();
  }
}

export interface GiftCalendarTask {
  question: string,
  answers: List<string>,
  image: string,
  maxTries: number,
}

export interface CardItem {
  name: string,
  description: string,
  birthYear: string,
  deathYear: string,
  color: string,
  image: string,
  rarity: number,
}

export interface GenericItem {
  name: string,
  image: string,
  scale?: number,
}

export interface Item {
  type: string,
  generic?: GenericItem,
  card?: CardItem,
  quidditchBat?: GenericItem,
}

export interface GiftCalendarUnlockReward {
  type: string,
  items?: List<Item>,
  amount?: number,
}

export interface GiftCalendarUnlockProgress {
  nTries: number,
  guesses: List<string>,
  correct: boolean,
  rewards: List<GiftCalendarUnlockReward>,
  loading: boolean,
}

export class GiftCalendarUnlockDetails extends ObjectCopier{
  constructor(
    public readonly loading: boolean,
    public readonly fail: boolean,
    public readonly task: GiftCalendarTask,
    public readonly progress: GiftCalendarUnlockProgress,
  ) {
      super();
  }
}

export interface GiftCalendarAdminState {
  placingUnlockId: string,
  position1: Position,
}

export interface GiftCalendarState {
  calendars: List<GiftCalendar>,
  unlockDetails: Map<string, GiftCalendarUnlockDetails>,
  loading: boolean,
  admin: GiftCalendarAdminState,
}


export const findGiftCalendar = (state: GiftCalendarState, id: string): GiftCalendar => {
  for (var calendar of state.calendars) {
    if (calendar.id === id)
      return calendar
  }
}

export const findGiftCalendarUnlockDetails = (state: GiftCalendarState, id: string): GiftCalendarUnlockDetails => {
  return state.unlockDetails.get(id)
}

const mergeCalendars = (calendars: List<GiftCalendar>): List<GiftCalendar> => {
  const unique = deduplicateList(calendars, (c1, c2) => { return c1.id === c2.id })
  return unique.sort((c1, c2) => {
    if (c1 === null && c2 !== null)
      return -1

    if (c2 === null && c1 !== null)
      return -1

    if (c1 === null && c2 === null)
      return 0

    return c2.startDate.getTime() - c1.startDate.getTime()
  })
}


const initialState: GiftCalendarState = {
  calendars: List(),
  unlockDetails: Map(),
  loading: false,
  admin: {
    placingUnlockId: null,
    position1: null,
  },
}


export const giftCalendarReducer = (state = initialState, action): GiftCalendarState => {
  switch (action.type) {
    case GET_GIFT_CALENDARS_SUCCESS:
      return {...state, calendars: mergeCalendars(action.giftCalendars.concat(state.calendars))}
    case POST_GIFT_CALENDAR_SUCCESS:
      return {...state, calendars: mergeCalendars(state.calendars.push(action.giftCalendar))}
    case GET_GIFT_CALENDAR:
      return {...state, loading: true}
    case GET_GIFT_CALENDAR_SUCCESS:
      return {
        ...state,
        loading: false,
        calendars: mergeCalendars(state.calendars.push(action.giftCalendar)),
      }
    case GET_GIFT_CALENDAR_FAIL:
      return {...state, loading: false}
    case POST_GIFT_CALENDAR_UNLOCK_SUCCESS:
    {
      const actionT = action as PostGiftCalendarUnlockSuccess
      return {...state, calendars: state.calendars.map(calendar => {
        if (calendar.id === actionT.giftCalendarId) {
          return calendar.copyWith({
            unlocks: calendar.unlocks.push(actionT.unlock),
          })
        }

        return calendar
      })}
    }
    case GET_GIFT_CALENDAR_UNLOCK_DETAILS:
      return {
        ...state,
        unlockDetails: state.unlockDetails.set(action.unlockId, new GiftCalendarUnlockDetails(true, false, null, null)),
      }
    case GET_GIFT_CALENDAR_UNLOCK_DETAILS_SUCCESS:
      const actionT = action as GetGiftCalendarUnlockDetailsSuccess
      return {
        ...state,
        unlockDetails: state.unlockDetails.set(actionT.unlockId, actionT.details),
      }
    case GET_GIFT_CALENDAR_UNLOCK_DETAILS_FAIL:
      return {
        ...state,
        unlockDetails: state.unlockDetails.set(action.unlockId, new GiftCalendarUnlockDetails(false, true, null, null)),
      }
    case LISTEN_FOR_GIFT_CALENDAR_PLACEMENT:
      return {
        ...state,
        admin: {
          placingUnlockId: action.unlockId,
          position1: null,
        },
      }
    case REGISTER_GIFT_CALENDAR_PLACEMENT:
      if (state.admin.position1 === null) {
        return {
          ...state,
          admin: {
            ...state.admin,
            position1: action.position,
          }
        }
      } else {
        return {
          ...state,
          calendars: state.calendars.map((calendar) => {
            return calendar.copyWith({
              unlocks: calendar.unlocks.map((unlock) => {
                if (unlock.id == state.admin.placingUnlockId) {
                  return unlock.copyWith({
                    pos1: state.admin.position1,
                    pos2: action.position,
                  })
                } else {
                  return unlock
                }
              }),
            })
          }),
          admin: {
            ...state.admin,
            placingUnlockId: null,
          }
        }
      }
    case UPDATE_GIFT_CALENDAR_UNLOCK_SUCCESS:
    {
      const actionT = action as UpdateGiftCalendarUnlockSuccess
      return {
        ...state,
        calendars: state.calendars.map((calendar) => {
          return calendar.copyWith({
            unlocks: calendar.unlocks.map((unlock) => {
              if (unlock.id === action.unlockId) {
                return actionT.unlock
              } else {
                return unlock
              }
            }),
          })
        }),
        unlockDetails: state.unlockDetails.set(actionT.unlock.id, actionT.details),
      }
    }
    case POST_GIFT_CALENDAR_UNLOCK_GUESS:
    {
      const actionT = action as PostGiftCalendarUnlockGuess
      return {
        ...state,
        unlockDetails: state.unlockDetails.update(actionT.unlockId, (details) => details.copyWith({
          progress: {
            ...details.progress,
            loading: true,
          },
        })),
      }
    }
    case POST_GIFT_CALENDAR_UNLOCK_GUESS_SUCCESS:
    {
      const actionT = action as PostGiftCalendarUnlockGuessSuccess
      return {
        ...state,
        unlockDetails: state.unlockDetails.set(actionT.unlockId, actionT.details),
      }
    }
    case POST_GIFT_CALENDAR_UNLOCK_GUESS_FAIL:
    {
      const actionT = action as PostGiftCalendarUnlockGuessFail
      return {
        ...state,
        unlockDetails: state.unlockDetails.set(actionT.unlockId, new GiftCalendarUnlockDetails(false, true, null, null)),
      }
    }
    default:
      return state
  }
}