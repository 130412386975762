import {getCookies} from '../utils'

import originalFetch from 'isomorphic-unfetch'
import fetchRetryBuilder from 'fetch-retry'
import { GiftCalendarTask, Position } from '../redux/state/gift_calendar'

const fetch = fetchRetryBuilder(originalFetch)


class NotFoundException {}
class AuthException {}


const getAuthorization = () => {
  const cookies = getCookies()

  if (!cookies['user_id'] || !cookies['auth_token'])
    return;

  return 'Basic ' + btoa(cookies['user_id'] + ':' + cookies['auth_token'])
}

const retryDelay = (attempt, error, response) => Math.pow(2, attempt) * 50

const idempotentRetryCondition = (attempt, error, response) => {
  if (attempt <= 5) {
    if (error)
      return true

    if (response.status >= 500 && response.status <= 599 && response.status != 501)
      return true
  }
}


const get = async (url: string, {query}: {query?: object} = {}) => {
  if (query) {
    const params = new URLSearchParams()

    for (const key of Object.keys(query)) {
      const value = query[key]

      if (value instanceof Array) {
        for (const member of value) {
          params.append(key + '[]', member)
        }
      } else {
        params.append(key, value)
      }
    }

    url += '?' + params.toString()
  }

  let headers: any = {}

  let auth = getAuthorization()

  if (auth)
    headers.Authorization = auth

  return await fetch(url, {
    headers: headers,
    retryDelay: retryDelay,
    retryOn: idempotentRetryCondition,
  })
}

const postBody = (url: string, body: BodyInit) => {
  return _sendBody('POST', url, body)
}

const putBody = async (url: string, body: BodyInit) => {
  return _sendBody('PUT', url, body)
}

const _sendBody = async (method: string, url: string, body: BodyInit) => {
  let headers: any = {}

  let auth = getAuthorization()

  if (auth)
    headers.Authorization = auth

  return await fetch(url, {
    method: method,
    body: body,
    headers: headers,
    retryDelay: retryDelay,
  })
}

const postJson = (url: string, json: object) => {
  return _sendJson('POST', url, json)
}

const putJson = (url: string, json: object) => {
  return _sendJson('PUT', url, json)
}

const _sendJson = async (method: string, url: string, json: object) => {
  let headers: any = {
    'Content-Type': 'application/json',
  }

  let auth = getAuthorization()

  if (auth)
    headers.Authorization = auth

  return await fetch(url, {
    method: method,
    body: JSON.stringify(json),
    headers: headers,
    retryDelay: retryDelay,
  })
}

const patchJson = async (url: string, json: object) => {
  let headers: any = {
    'Content-Type': 'application/json',
  }

  let auth = getAuthorization()

  if (auth)
    headers.Authorization = auth

  return await fetch(url, {
    method: 'PATCH',
    body: JSON.stringify(json),
    headers: headers,
    retryDelay: retryDelay,
    retryOn: idempotentRetryCondition,
  })
}

const _delete = async (url: string) => await fetch(url, {
  method: 'DELETE',
  headers: {
    Authorization: getAuthorization(),
  },
  retryDelay: retryDelay,
  retryOn: idempotentRetryCondition,
});

export const isLoggedIn = () => {
  const cookies = getCookies()

  return cookies && !!cookies['auth_token']
}

export interface GiftCalendarUnlockUpdate {
  pos1: Position,
  pos2: Position,
  task: {
    question: string,
    answers: string[],
    image: string,
    n_tries: number,
  },
}

const dumpPosition = (position: Position) => {
  return {
    x: position.x,
    y: position.y,
  }
}

const dumpGiftCalendarUnlockUpdate = (update: GiftCalendarUnlockUpdate) => {
  return {
    pos1: dumpPosition(update.pos1),
    pos2: dumpPosition(update.pos2),
    task: update.task,
  }
}


export const mugglis = {
  getGiftCalendars: async () => {
    const resp = await get(`api/v1/gift-calendars`)

    if (resp.status === 404)
      throw new NotFoundException()

    const body = await resp.json()
    return body
  },

  postGiftCalendar: async (name: string, image: string, start_date: Date, end_date: Date) => {
    const resp = await postJson(`api/v1/gift-calendars`, {
      name,
      image,
      start_date,
      end_date,
    })

    const body = await resp.json()
    return body
  },

  getGiftCalendar: async (id: string) => {
    const resp = await get(`api/v1/gift-calendars/${id}`)
  
    if (resp.status === 404)
      throw new NotFoundException()

    const body = await resp.json()
    return body
  },

  getGiftCalendarUnlockDetails: async (unlockId: string) => {
    if (!isLoggedIn())
      throw new AuthException()

    const resp = await get(`api/v1/gift-calendar-unlocks/${unlockId}/details`)
    return await resp.json()
  },

  postGiftCalendarUnlock: async (giftCalendarId: string, name: string, unlockDate: Date, task: GiftCalendarTask) => {
    if (!isLoggedIn())
      throw new AuthException()

    const resp = await postJson(`api/v1/gift-calendars/${giftCalendarId}/unlocks`, {
      name,
      unlock_date: unlockDate,
      task,
    })
    return await resp.json()
  },

  patchGiftCalendarUnlock: async (unlockId: string, update: GiftCalendarUnlockUpdate) => {
    if (!isLoggedIn())
      throw new AuthException()

    const resp = await patchJson(`api/v1/gift-calendar-unlocks/${unlockId}`, dumpGiftCalendarUnlockUpdate(update))
    return await resp.json()
  },

  patchItemCode: async (id: string, name: string) => {
    if (!isLoggedIn())
      throw new AuthException()

      const resp = await patchJson(`api/v1/item-codes/${id}`, {
        name,
      })
      return await resp.json()
  },

  putItemCodeImage: async (id: string, image: string) => {
    if (!isLoggedIn())
      throw new AuthException()

    const resp = await putJson(`api/v1/item-codes/${id}/image`, { image })
    return await resp.json()
  },

  postGiftCalendarUnlockGuess: async (unlockId: string, guess: string) => {
    if (!isLoggedIn())
      throw new AuthException()

    const resp = await postJson(`api/v1/gift-calendar-unlocks/${unlockId}/guesses`, {
      guess: guess,
    })
    return await resp.json()
  },

  postImage: async(file: File) => {
    if (!isLoggedIn())
      throw new AuthException()

    const formData = new FormData()
    formData.append("image", file)

    const resp = await postBody('api/v1/images', formData)
    return await resp.json()
  },

  postItemCode: async (code: string, name: string, image: string) => {
    if (!isLoggedIn())
      throw new AuthException()

    const resp = await postJson(`api/v1/item-codes`, {
      code,
      name,
      image,
    })
    return await resp.json()
  },

  postWebsocketToken: async (token: string) => {
    if (!isLoggedIn())
      throw new AuthException()

    const resp = await postJson("api/v1/me/websocket-tokens", {
      token: token,
    })

    const body = await resp.json()
    return body
  },

  getUsersBatch: async (ids: string[]) => {
    if (!isLoggedIn())
      throw new AuthException()

    const query = {
      'ids': ids,
    }

    const resp = await get("api/v1/users/batch", {query})

    const body = await resp.json()
    return body
  },

  getConversations: async (cursor: string) => {
    if (!isLoggedIn())
      throw new AuthException()

    const query = {}

    if (cursor)
      query['cursor'] = cursor

    const resp = await get("api/v1/me/conversations", {query})

    const body = await resp.json()
    return body
  },

  getConversation: async (conversationId: string) => {
    if (!isLoggedIn())
      throw new AuthException()

    const resp = await get(`api/v1/me/conversations/${conversationId}`)
  
    if (resp.status === 404)
      throw new NotFoundException()

    const body = await resp.json()
    return body
  },

  getConversationMessages: async (conversationId: string, cursor: string) => {
    if (!isLoggedIn())
      throw new AuthException()

    const query = {}

    if (cursor)
      query['cursor'] = cursor

    const resp = await get(`api/v1/me/conversations/${conversationId}/messages`, {query})

    const body = await resp.json()
    return body
  },

  getItemCodes: async () => {
    if (!isLoggedIn())
      throw new AuthException()

    const resp = await get("api/v1/item-codes");
    const body = await resp.json()
    return body
  },

  getSettings: async () => {
    if (!isLoggedIn())
      throw new AuthException()

    const resp = await get('api/v1/me/settings')
    return await resp.json()
  },

  sendConversationMessage: async (conversationId: string, text: string) => {
    if (!isLoggedIn())
      throw new AuthException()

    const resp = await postJson(`api/v1/me/conversations/${conversationId}/messages`, {
      text: text,
    })

    const body = await resp.json()
    return body
  },

  patchConversation: async (conversationId: string, conversation: object) => {
    if (!isLoggedIn())
      throw new AuthException()

    await patchJson(`api/v1/me/conversations/${conversationId}`, conversation)
  },

  deleteMessage: async (messageId: string) => {
    if (!isLoggedIn())
      throw new AuthException()

    await _delete(`api/v1/me/messages/${messageId}`)
  }
}
