import React from 'react'
import { followURL } from "../../utils"


interface LocalLinkProps {
  url: string,
  children: React.ReactNode,
}


export const LocalLink: React.FC<LocalLinkProps> = ({url, children}) =>
  <a
    href={url}
    onClick={(e) => {
      e.preventDefault()
      return followURL(url);
    }}
  >
    {children}
  </a>
