import dayjs from "dayjs"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getGiftCalendars, postGiftCalendars } from "../redux/actions"
import { AppState } from "../redux/reducers"
import { FormattedDate } from "./utils/FormattedDate"
import { Link } from "./utils/Link"


const GiftCalendarsAdmin: React.FC = () => {
    const [expanded, setExpanded] = useState(false);
    const user = useSelector((state: AppState) => state.user.settings.user)
    const dispatch = useDispatch()

    if (!user || user.status.id < 3) return <></>

    if (!expanded)
      return <p><button onClick={() => setExpanded(true)}>Admin</button></p>

    const todayStr = dayjs().format("YYYY-MM-DDT00:00")

    return <>
      <h2>Lägg till kalender</h2>
      <form
        onSubmit={(e) => {
            e.preventDefault();
            const form = e.target as HTMLFormElement
            const name = form.elements["name"].value
            const image = form.elements["image"].files[0]
            const startDate = dayjs(form.elements["startDate"].value).toDate()
            const endDate = dayjs(form.elements["endDate"].value).toDate()

            dispatch(postGiftCalendars(name, image, startDate, endDate))
            return false
        }}
      >
        <p>
            Namn: <input name="name" type="text" />
        </p>
        <p>
            Bild: <input
                type="file"
                accept="image/png, image/jpeg"
                name="image"
            />
        </p>
        <p>
            Datum:
            <input name="startDate" type="datetime-local" defaultValue={todayStr} />
            -
            <input name="endDate" type="datetime-local" defaultValue={todayStr} />
        </p>
        <p>
            <input type="submit" value="Lägg till" />
        </p>
      </form>
    </>
}

export const GiftCalendarListPage: React.FC = () => {
  const state = useSelector((state: AppState) => state.giftCalendar)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getGiftCalendars())
  }, [])

  return <>
    <h1>Kalendrar</h1>

    {state.calendars.isEmpty() ? '' :
        <table>
            <thead>
                <tr>
                    <th>Namn</th>
                    <th colSpan={3}>Datum</th>
                </tr>
            </thead>
            <tbody>
                {state.calendars.map(calendar => {
                    return <tr key={calendar.id}>
                        <td>
                            <Link url={`/gift-calendars/${calendar.id}`}>{calendar.name}</Link>
                        </td>
                        <td>
                            <FormattedDate date={calendar.startDate} />
                        </td>
                        <td>-</td>
                        <td>
                            <FormattedDate date={calendar.endDate} />
                        </td>
                    </tr>
                })}
            </tbody>
        </table>
    }

    <GiftCalendarsAdmin />
  </>
}
