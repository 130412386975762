import React, { useState } from 'react'
import {emoticon, tagMessage, color, useFont, link} from '../../modules/bbcode'


export const smilieyRows = [
  [
    { image: 'icon_smile.gif', code: ':)', title: '' },
    { image: 'icon_wink.gif', code: ';)', title: '' },
    { image: 'icon_razz.gif', code: ':P', title: '' },
    { image: 'icon_biggrin.gif', code: ':D', title: '' },
    { image: 'icon_confused.gif', code: ':/', title: '' },
    { image: 'icon_sad.gif', code: ':(', title: '' },
    { image: 'icon_cool.gif', code: '8)', title: '' },
  ],
  [
    { image: 'icon_harry.gif', code: '(H)', title: 'Harry' },
    { image: 'icon_red.gif', code: '(W)', title: 'Weasley' },
    { image: 'icon_hermione.gif', code: '(E)', title: 'Hermione' },
    { image: 'icon_draco.gif', code: '(R)', title: 'Draco' },
    { image: 'luna.png', code: '(L)', title: 'Luna' },
    { image: 'icon_neville.gif', code: '(N)', title: 'Neville' },
  ],
  [
    { image: 'icon_hagrid.gif', code: '(A)', title: 'Hagrid' },
    { image: 'icon_greasy.gif', code: '(S)', title: 'Snape' },
    { image: 'icon_voldy.gif', code: '(V)', title: 'Ni-vet-vem' },
    { image: 'icon_dumby.gif', code: '(D)', title: 'Dumbledore' },
  ],
]

const colorRows = [
  [
    { color: 'Röd', code: 'red' },
    { color: 'Grön', code: 'green' },
    { color: 'Gul', code: 'yellow' },
    { color: 'Blå', code: 'blue' },
    { color: 'Lila', code: 'purple' },
  ],
  [
    { color: 'Svart', code: 'black' },
    { color: 'Grå', code: 'grey' },
    { color: 'Cerise', code: '#E2007F' },
    { color: 'Rosa', code: 'pink' },
    { color: 'Orange', code: 'orange' },
  ],
]

const fonts = [
  'Verdana',
  'Comic Sans MS',
  'Arial',
  'Times New Roman',
  'Tahoma',
  'Courier New',
  'Century Gothic',
]

interface BBCodePanelProps {
  value: string,
  start: number,
  end: number,
  images?: boolean,
  setValue(value: string),
}

export const BBCodePanel: React.FC<BBCodePanelProps> = ({value, start, end, images, setValue}) => {
  const [selectedFont, setSelectedFont] = useState('default')

  const addEmote = (emote: string) => {
    setValue(emoticon(value, start, end, emote))
  }

  const tag = (tag: string) => {
    const newValue = tagMessage(value, start, end, tag, 'Skriv in texten som ska formateras:')
    setValue(newValue)
  }

  const applyColor = (code: string) => {
    setValue(color(value, start, end, code))
  }

  const applyFont = (font: string) => {
    setSelectedFont(font)
    setValue(useFont(value, start, end, font))
  }

  const addLink = () => {
    setValue(link(value, start, end))
  }

  const addImage = () => {
    setValue(tagMessage(value, start, end, 'img', 'URL:en till bilden:'))
  }

  const addSpoiler = () => {
    setValue(tagMessage(value, start, end, 'spoiler', 'Spoilern du vill dölja:'))
  }

  const addUserTag = () => {
    setValue(tagMessage(value, start, end, 'user', 'Medlemmen du länkar till:'))
  }

  // TODO: hide smilies for users that disabled them
  return <>
    <h3 style={{display: 'inline'}}>Smilies</h3>
    <br />
    {smilieyRows.map((row, index) => {
      return <React.Fragment key={index}>
        {row.map(smiley => <img
          key={smiley.code}
          src={`images/smilies/${smiley.image}`}
          className='smiliey'
          alt={smiley.code}
          onClick={() => addEmote(smiley.code)}
        />)}
        <br />
      </React.Fragment>
    })}
    <br />

    <h3 style={{display: 'inline'}}>Formatering</h3>

    <br /> 

    <input type='button' value='b' title='Fet' style={{fontWeight: 'bold', float: 'left'}} onClick={() => tag('b')} />
    <input type='button' value='i' title='Kursiv' style={{fontStyle: 'italic', float: 'left'}} onClick={() => tag('i')} />
    <input type='button' value='u' title='Understruket' style={{textDecoration: 'underline', float: 'left'}} onClick={() => tag('u')} />
    <input type='button' value='s' title='Struket' style={{textDecoration: 'line-through', float: 'left'}} onClick={() => tag('s')} />

    <br /><br />

    <h4 style={{display: 'inline'}}>Textfärg</h4>
    <br />
    {colorRows.map((row, index) => {
      return <React.Fragment key={index}>
        {row.map(({color, code}) => {
          return <input
            key={code}
            type='button'
            value=''
            title={color}
            style={{backgroundImage: 'none', backgroundColor: code}}
            onClick={() => applyColor(code)}
          />
        })}
        <br />
      </React.Fragment>
    })}

    <br />

    <h4 style={{display: 'inline'}}>Font</h4>

    <br />

    <select name='font' id='font' value={selectedFont} onChange={(e) => applyFont(e.target.value)}>
      <option value='default'>Välj font...</option>
      {fonts.map(font => {
        return <option key={font} value={font} style={{fontFamily: font}}>{font}</option>
      })
      }
    </select>

  <br />

  <p>
    <input type='button' value='Länk' title='Infoga länk' onClick={() => addLink()} />
    {
      images ?
      <input type='button' value='Bild' title='Infoga bild' onClick={() => addImage()} />
      :
      ''
    }
  </p>
  <p>
    <input type='button' value='Medlem' title='Medlem' onClick={() => addUserTag()} />
    <input type='button' value='Spoiler' title='Infoga spoiler-markering' onClick={() => addSpoiler()} />
  </p>
  </>
}

BBCodePanel.defaultProps = {
  images: true,
}