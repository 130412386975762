import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import { getItemCodes, patchItemCode } from "../redux/actions"
import { AppState } from "../redux/reducers"
import { Link } from "./utils/Link"


interface ItemCodeEditPageProps {
  itemCodeId: string
}

export const ItemCodeEditPage: React.FC = () => {
  const {itemCodeId} = useParams<ItemCodeEditPageProps>()
  const user = useSelector((state: AppState) => state.user.settings.user)
  const state = useSelector((state: AppState) => state.itemCode)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getItemCodes())
  }, [])

  if (!user || user.status.id < 3) return <></>

  const itemCode = state.itemCodes.find(ic => ic.id === itemCodeId)

  return <>
    <h1>Typer av ägodelar</h1>

    <p>
      <Link url={`/admin/item-codes`}>Tillbaka</Link>
    </p>

    {!itemCode ? '' :
      <>
        <h2>{itemCode.name}</h2>

        <table>
          <tbody>
            <tr>
              <td>Kod:</td>
              <td>{itemCode.code}</td>
            </tr>
            <tr>
              <td>Namn:</td>
              <td>{itemCode.name}</td>
            </tr>
            <tr>
              <td>Bild:</td>
              <td>
                <img src={itemCode.image} alt={itemCode.name} />
              </td>
            </tr>
          </tbody>
        </table>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            const form = e.target as HTMLFormElement
            const name = form.elements["name"].value

            if (name.length > 0 && name != itemCode.name)
              dispatch(patchItemCode(itemCode.id, name, undefined))

            return false
          }}
        >
          <p>
            Namn: <input name="name" type="text" defaultValue={itemCode.name} />
            <input type="submit" value="Ändra" />
          </p>
        </form>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            const form = e.target as HTMLFormElement
            const image = form.elements["image"].files[0]

            if (image) {
              dispatch(patchItemCode(itemCode.id, undefined, image))
              form.elements["image"].value = ""
            }

            return false
          }}
        >
          <p>
            Bild: <input
              type="file"
              accept="image/png, image/jpeg"
              name="image"
            />
            <input type="submit" value="Ändra" />
          </p>
        </form>
      </>
    }
  </>
}
