import {combineReducers} from 'redux'
import {chatReducer} from './state/chat'
import {giftCalendarReducer} from './state/gift_calendar'
import { itemCodeReducer } from './state/item_code'
import {userReducer} from './state/user'


export const reducer = combineReducers({
    chat: chatReducer,
    giftCalendar: giftCalendarReducer,
    itemCode: itemCodeReducer,
    user: userReducer,
})

export type AppState = ReturnType<typeof reducer>
