import {createStore, applyMiddleware} from 'redux'
import {composeWithDevTools} from 'redux-devtools-extension'
import {reducer} from './reducers'
import {apiMiddleware} from './middleware/mugglis'
import {websocketMiddleware} from './middleware/websocket'

export const store = createStore(
    reducer,
    composeWithDevTools(
        applyMiddleware(apiMiddleware, websocketMiddleware),
    ),
)
