import {Store} from 'redux'
import {
  POST_WEBSOCKET_TOKEN, GET_CONVERSATIONS, GET_CONVERSATION,
  GET_CONVERSATION_MESSAGES, SEND_CONVERSATION_MESSAGE,
  onGetUsers,
  GET_USER,
  GET_SETTINGS,
  MARK_CONVERSATION_READ,
  getConversationsSuccess,
  getConversationSuccess,
  getConversationMessagesSuccess,
  sendConversationMessageSuccess,
  getConversationsFail,
  getConversationFail,
  getConversationMessagesFail,
  sendConversationMessageFail,
  getSettingsSuccess,
  getSettingsFail,
  GET_GIFT_CALENDAR,
  getGiftCalendarSuccess,
  getGiftCalendarFail,
  GET_GIFT_CALENDAR_UNLOCK_DETAILS,
  getGiftCalendarUnlockDetailsSuccess,
  getGiftCalendarUnlockDetailsFail,
  UPDATE_GIFT_CALENDAR_UNLOCK,
  updateGiftCalendarUnlockSuccess,
  updateGiftCalendarUnlockFail,
  UpdateGiftCalendarUnlock,
  POST_GIFT_CALENDAR_UNLOCK_GUESS,
  PostGiftCalendarUnlockGuess,
  postGiftCalendarUnlockGuessSuccess,
  postGiftCalendarUnlockGuessFail,
  DELETE_MESSAGE,
  DeleteMessage,
  GET_GIFT_CALENDARS,
  getGiftCalendarsSuccess,
  getGiftCalendarsFail,
  postGiftCalendarSuccess,
  POST_GIFT_CALENDAR,
  GET_ITEM_CODES,
  getItemCodesFail,
  getItemCodesSuccess,
  POST_ITEM_CODE,
  PostItemCode,
  postItemCodeFail,
  postItemCodeSuccess,
  PATCH_ITEM_CODE,
  PatchItemCode,
  patchItemCodeFail,
  patchItemCodeSuccess,
  POST_GIFT_CALENDAR_UNLOCK,
  PostGiftCalendarUnlock,
  postGiftCalendarUnlockSuccess,
  postGiftCalendarUnlockFail
} from "../actions"
import {AppState} from '../reducers'
import {Message, Conversation} from "../state/chat";
import {User, Team, Settings, Status} from "../state/user"
import {mugglis} from '../../services/mugglis'
import {deduplicate, playSound} from "../../utils";
import { GiftCalendar, GiftCalendarUnlock, GiftCalendarUnlockDetails, Position, GiftCalendarTask, GiftCalendarUnlockProgress, GiftCalendarUnlockReward, Item} from '../state/gift_calendar';
import { List } from 'immutable';
import { ItemCode } from '../state/item_code';


const parsePosition = (json): Position => {
  return {
    x: json.x,
    y: json.y,
  }
}

const parseDate = (json): Date => {
  if (json === null)
    return null

  return new Date(json)
}

const parseGiftCalendarUnlock = (json): GiftCalendarUnlock => {
  return new GiftCalendarUnlock(
    json.id,
    json.name,
    parsePosition(json.pos1),
    parsePosition(json.pos2),
    new Date(json.start_date),
  )
}

const parseGiftCalendar = (json): GiftCalendar => {
  return new GiftCalendar(
    json.id,
    json.name,
    parseDate(json.start_date),
    parseDate(json.end_date),
    json.image,
    List<GiftCalendarUnlock>(json.unlocks.map(parseGiftCalendarUnlock)),
  )
}

const parseGiftCalendarUnlockTask = (json): GiftCalendarTask => {
  return {
    question: json.question,
    answers: json.answers || List(),
    image: json.image,
    maxTries: json.max_tries,
  }
}

const parseItem = (json): Item => {
  const type: string = json.type
  const result: Item = {
    type: type,
  }

  if (type === "card") {
    result.card = {
      name: json.card.name,
      description: json.card.description,
      birthYear: json.card.birth_year,
      deathYear: json.card.death_year,
      image: json.card.image,
      rarity: json.card.rarity,
      color: json.card.color,
    }
  } else if(type === 'wand') { // Handle wands
    throw "TODO: Implement wand";
  } else if (type === "dragon") { // Handle dragons
    throw "TODO: Implement dragon";
  } else if (type === "dragon_blood") { // Handle dragon blood
    throw "TODO: Implement dragon_blood";
  } else if (type === "animal") { // Handle animals
    throw "TODO: Implement animal";
  } else if (type === "christmas_pug") {
    throw "TODO: Implement christmas_pug";
  } else if (type === "christmas_pug2") {
    throw "TODO: Implement christmas_pug2";
  } else if (type === "weasley_shirt") {
    throw "TODO: Implement weasley_shirt";
  } else if (type === "quidditch_bat") {
    result.quidditchBat = {
      name: json.quidditch_bat.name,
      image: json.quidditch_bat.image,
    }
  } else if (type === "generic") {
    result.generic = {
      name: json.generic.name,
      image: json.generic.image,
    }
  } else {
    throw 'Invalid item type: ' + type;
  }

  return result
}


const parseGiftCalendarUnlockReward = (json): GiftCalendarUnlockReward => {
  const result: GiftCalendarUnlockReward = {
    type: json.type,
  }

  if (result.type === "money") {
    result.amount = json.amount
  } else if (result.type === "items") {
    result.items = json.items.map(parseItem)
  }

  return result
}

const parseGiftCalendarUnlockProgress = (json): GiftCalendarUnlockProgress => {
  if (!json) return null

  return {
    nTries: json.n_tries,
    correct: json.correct,
    guesses: json.guesses,
    rewards: json.rewards ? json.rewards.map(parseGiftCalendarUnlockReward) : [],
    loading: false,
  }
}

const parseGiftCalendarUnlockDetails = (json): GiftCalendarUnlockDetails => {
  return new GiftCalendarUnlockDetails(
    false,
    false,
    parseGiftCalendarUnlockTask(json.task),
    parseGiftCalendarUnlockProgress(json.progress),
  )
}

const parseTeam = (json): Team => {
  return {
    id: json.id,
    name: json.name,
    color: json.color
  }
}

const parseStatus = (json): Status => {
  return {
    id: json.id,
    name: json.name,
  }
}

const parseUser = (json): User => {
  const result: User = {
    id: json.id,
    name: json.name,
  }

  if (json['avatar'])
    result.avatar = new URL(json['avatar'])

  if (json['team'])
    result.team = parseTeam(json.team)

  if (json['status'])
    result.status = parseStatus(json.status)

  if (json['deleted'])
    result.deleted = true

  if (json['system'])
    result.system = true

  return result
}

const parseSettings = (json): Settings => {
  return {
    user: parseUser(json['user']),
    showSmileys: json['show_smileys'],
    sounds: json['sounds'],
  }
}

const parseMessage = (json): Message => {
  return {
    id: json.id,
    user: parseUser(json.user),
    text: json.text,
    date: new Date(json.date),
    read: json.read,
    failed: false,
  }
}

const parseConversation = (json): Conversation => {
  return {
    id: json.id as string,
    user: parseUser(json.user),
    messages: [parseMessage(json.last_message)],
    cursor: null,
    loading: false,
    markReadInFlight: false,
  }
}

const parseItemCode = (json): ItemCode => {
  return {
    id: json.id,
    code: json.code,
    name: json.name,
    image: json.image,
  }
}


export const apiMiddleware = (store: Store<AppState>) => {
  const state: {
    userIdsToFetch: string[],
    fetchingUsers: boolean,
  } = {
    userIdsToFetch: [],
    fetchingUsers: false,
  }

  const fetchBatchUsers = async () => {
    if (state.userIdsToFetch.length <= 0)
      return

    state.fetchingUsers = true

    const userIdsToFetch = state.userIdsToFetch
    state.userIdsToFetch = []

    try {
      const userIds = deduplicate(userIdsToFetch, (id1, id2) => id1 === id2)
      const json = await mugglis.getUsersBatch(userIds)
      const users = json.users.map(parseUser)

      for (let i = 0; i < users.length; ++i) {
        if (users[i].deleted) {
          users[i].name = userIds[i]
        }
      }

      store.dispatch(onGetUsers(users))
    } catch (e) {
      console.error(e)
      // TODO: check error type?
      state.userIdsToFetch = state.userIdsToFetch.concat(userIdsToFetch)
    }

    if (state.userIdsToFetch.length >= 0)
      await fetchBatchUsers()
    else
      state.fetchingUsers = false
  }

  return next => async action => {
    next(action)

    switch (action.type) {
      case POST_WEBSOCKET_TOKEN:
        await mugglis.postWebsocketToken(action.token)
        break
      case GET_GIFT_CALENDARS:
        try {
          const json = await mugglis.getGiftCalendars()
          const giftCalendars = List<GiftCalendar>(json.gift_calendars.map(parseGiftCalendar))
          store.dispatch(getGiftCalendarsSuccess(giftCalendars))
        } catch (e) {
          console.error(e)
          store.dispatch(getGiftCalendarsFail())
        }
        break
      case POST_GIFT_CALENDAR:
        try {
          const image_json = await mugglis.postImage(action.image)
          const json = await mugglis.postGiftCalendar(action.name, image_json.image, action.startDate, action.endDate)
          const giftCalendar = parseGiftCalendar(json.gift_calendar)
          store.dispatch(postGiftCalendarSuccess(giftCalendar))
        } catch (e) {
          console.error(e)
          store.dispatch(getGiftCalendarsFail())
        }
        break
      case GET_GIFT_CALENDAR:
        try {
          const json = await mugglis.getGiftCalendar(action.calendarId)
          const giftCalendar = parseGiftCalendar(json.gift_calendar)
          store.dispatch(getGiftCalendarSuccess(giftCalendar))
        } catch (e) {
          console.error(e)
          store.dispatch(getGiftCalendarFail())
        }
        break
      case POST_GIFT_CALENDAR_UNLOCK:
      {
        const actionT = action as PostGiftCalendarUnlock
        try {
          const task = {
            question: '',
            answers: List<string>(),
            image: '',
          } as GiftCalendarTask;
          const json = await mugglis.postGiftCalendarUnlock(actionT.calendarId, actionT.name, actionT.unlockDate, task)
          const unlock = parseGiftCalendarUnlock(json.unlock)
          const details = parseGiftCalendarUnlockDetails(json.details)
          store.dispatch(postGiftCalendarUnlockSuccess(actionT.calendarId, unlock, details))
        } catch (e) {
          console.error(e)
          store.dispatch(postGiftCalendarUnlockFail())
        }
        break;
      }
      case GET_GIFT_CALENDAR_UNLOCK_DETAILS:
        try {
          const json = await mugglis.getGiftCalendarUnlockDetails(action.unlockId)
          const details = parseGiftCalendarUnlockDetails(json)
          store.dispatch(getGiftCalendarUnlockDetailsSuccess(action.unlockId, details))
        } catch (e) {
          console.error(e)
          store.dispatch(getGiftCalendarUnlockDetailsFail(action.unlockId))
        }
        break
      case UPDATE_GIFT_CALENDAR_UNLOCK:
        const actionT = action as UpdateGiftCalendarUnlock
        try {
          if (actionT.imageFile) {
            const json = await mugglis.postImage(actionT.imageFile)
            actionT.update.task.image = json.image
          }

          const json = await mugglis.patchGiftCalendarUnlock(action.unlockId, action.update)
          const unlock = parseGiftCalendarUnlock(json.unlock)
          const details = parseGiftCalendarUnlockDetails(json.details)
          store.dispatch(updateGiftCalendarUnlockSuccess(unlock, details))
        } catch (e) {
          console.error(e)
          store.dispatch(updateGiftCalendarUnlockFail(action.unlockId))
        }
        break
      case POST_GIFT_CALENDAR_UNLOCK_GUESS:
      {
        const actionT = action as PostGiftCalendarUnlockGuess
        try {
          const json = await mugglis.postGiftCalendarUnlockGuess(actionT.unlockId, actionT.guess)
          const details = parseGiftCalendarUnlockDetails(json.details)
          store.dispatch(postGiftCalendarUnlockGuessSuccess(actionT.unlockId, details))
        } catch (e) {
          console.error(e)
          store.dispatch(postGiftCalendarUnlockGuessFail(actionT.unlockId))
        }
        break
      }
      case GET_ITEM_CODES:
      {
        try {
          const json = await mugglis.getItemCodes()
          const itemCodes = List<ItemCode>(json.item_codes.map(parseItemCode))
          store.dispatch(getItemCodesSuccess(itemCodes))
        } catch (e) {
          console.error(e)
          store.dispatch(getItemCodesFail())
        }
        break
      }
      case POST_ITEM_CODE:
      {
        const actionT = action as PostItemCode
        try {
          const image = (await mugglis.postImage(actionT.image)).image
          const json = await mugglis.postItemCode(actionT.code, actionT.name, image)
          const itemCode = parseItemCode(json.item_code)
          store.dispatch(postItemCodeSuccess(itemCode))
        } catch (e) {
          console.error(e)
          store.dispatch(postItemCodeFail())
        }
        break
      }
      case PATCH_ITEM_CODE:
      {
        const actionT = action as PatchItemCode

        try {
          let json = undefined
          if (actionT.name !== undefined) {
            json = await mugglis.patchItemCode(actionT.id, actionT.name )
          }

          if (actionT.image !== undefined) {
            let image = ""

            if (actionT.image !== null) {
              image = (await mugglis.postImage(actionT.image)).image
            }

            json = await mugglis.putItemCodeImage(actionT.id, image)
          }

          const itemCode = parseItemCode(json.item_code)
          store.dispatch(patchItemCodeSuccess(itemCode))
        } catch (e) {
          console.error(e)
          store.dispatch(patchItemCodeFail())
        }
        break
      }
      case GET_USER:
        state.userIdsToFetch.push(action.userId)
        setTimeout(async () => {
          if (!state.fetchingUsers)
            await fetchBatchUsers()
        }, 50)
        break;
      case GET_CONVERSATIONS:
        try {
          const json = await mugglis.getConversations(action.cursor)
          const conversations = json.conversations.map(parseConversation)
          const cursor = json.cursor
          store.dispatch(getConversationsSuccess(conversations, cursor))
        } catch (e) {
          console.error(e)
          store.dispatch(getConversationsFail())
        }
        break
      case GET_CONVERSATION:
        try {
          const json = await mugglis.getConversation(action.conversationId)
          const conversation = parseConversation(json.conversation)
          store.dispatch(getConversationSuccess(conversation))
        } catch (e) {
          console.error(e)
          store.dispatch(getConversationFail(action.conversationId))
        }
        break
      case GET_CONVERSATION_MESSAGES:
        try {
          const json = await mugglis.getConversationMessages(action.conversationId, action.cursor)
          const messages = json.messages.map(parseMessage)
          const cursor: string = json.cursor
          store.dispatch(getConversationMessagesSuccess(action.conversationId, messages, cursor));
          if (action.notify && store.getState().user.settings.sounds) {
            playSound('woosh')
          }
        } catch (e) {
          console.error(e)
          store.dispatch(getConversationMessagesFail(action.conversationId))
        }
        break
      case GET_SETTINGS:
        try {
          const json = await mugglis.getSettings()
          const settings = parseSettings(json)
          store.dispatch(getSettingsSuccess(settings))
        } catch (e) {
          console.log(e)
          store.dispatch(getSettingsFail())
        }
        break
      case SEND_CONVERSATION_MESSAGE:
        try {
          const json = await mugglis.sendConversationMessage(action.conversationId, action.text)
          const message = parseMessage(json.message)
          message.localId = action.localId
          store.dispatch(sendConversationMessageSuccess(action.conversationId, message))
        } catch (e) {
          console.error(e)
          store.dispatch(sendConversationMessageFail(action.conversationId, action.localId))
        }
        break
      case MARK_CONVERSATION_READ:
        await mugglis.patchConversation(action.conversationId, {
          last_message_read: action.lastMessageReadId,
        })
        break;
      case DELETE_MESSAGE:
      {
        const actionT = action as DeleteMessage

        try {
          await mugglis.deleteMessage(actionT.messageId)
        } catch (e) {
          console.error(e)
        }
      }
      default:
        break
    }

  }
}
