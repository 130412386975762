import React, { useEffect } from "react"
import {Provider, useDispatch} from 'react-redux'
import {ConversationListPage} from './components/ConversationListPage'
import {ConversationPage} from './components/ConversationPage'
import {Switch, Route} from 'react-router'
import {HashRouter} from 'react-router-dom'
import {store} from './redux/store'
import { getSettings } from "./redux/actions"
import { GiftCalendarPage } from "./components/GiftCalendarPage"
import { GiftCalendarUnlockPage } from "./components/GiftCalendarUnlockPage"
import { GiftCalendarListPage } from "./components/GiftCalendarListPage"
import { ItemCodeListPage } from "./components/ItemCodeListPage"
import { ItemCodeEditPage } from "./components/ItemCodeEditPage"


export const Router: React.FC = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getSettings())
  }, [])

  return <HashRouter hashType='noslash'>
    <Switch>
      <Route exact path="/admin/item-codes" component={ItemCodeListPage} />
      <Route exact path="/admin/item-codes/:itemCodeId" component={ItemCodeEditPage} />
      <Route exact path="/conversations" component={ConversationListPage} />
      <Route exact path="/conversations/:conversationId" component={ConversationPage} />
      <Route exact path="/gift-calendars/:calendarId/unlocks/:unlockId" component={GiftCalendarUnlockPage} />
      <Route exact path="/gift-calendars/:calendarId" component={GiftCalendarPage} />
      <Route exact path="/gift-calendars" component={GiftCalendarListPage} />
    </Switch>
  </HashRouter>
}

export const App: React.FC = () => <Provider store={store}>
  <Router />
</Provider>
