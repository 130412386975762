import fetch from 'isomorphic-unfetch'
import {Hangman, Scripts} from '../index'
import {findPosY} from './scripts'


export let currentUrl: string

const insertParam = (url: string, param: string) => {
    var hash = ""
    var query = []
    var data = url.split("#")

    url = data[0];

    if (data.length > 1)
       hash = data[1]

    data = url.split("?");
    url = data[0];

    if (data.length > 1) {
       query = data[1].split("&");
    }

    query.push(param);

    url = url + "?" + query.join("&") + (hash.length > 0 ? ("#" + hash) : "");

    return url;
}

const getPath = (url: string): string => {
  const parsed = new URL(url)
  return parsed.pathname.substr(1) + parsed.search + parsed.hash
}

export const showNameAvailable = (name, resultElementId) => {
  if (name.length < 2) {
    document.getElementById(resultElementId).innerHTML = ""
    return
  }

  const resultElement = document.getElementById(resultElementId)
  resultElement.innerHTML = "Söker..."
  resultElement.style.color = "#ffffff"

  fetch("javascript/find_user.php?q=" + encodeURIComponent(name)).then(resp => {
    resp.text().then(text => {
      if (text.length > 0) {
        resultElement.innerHTML = "Upptaget!"
        resultElement.style.color = "#ff0000"
      } else {
        resultElement.innerHTML = "Ledigt"
        resultElement.style.color = "#00ff00"
      }
    })
  })
}

let updateMenuTimeout: NodeJS.Timeout
let updateMenuOptions: UpdateMenuOptions

interface UpdateMenuOptions {
  updateInterval: number
  lastUpdate: number
  force: boolean,
  style: string
  mailImage: HTMLImageElement
  friendsImage: HTMLImageElement
  watchedImage: HTMLImageElement
  duelsImage: HTMLImageElement
  numUsersOnlineText: HTMLSpanElement
}

export const updateMenu = (options: UpdateMenuOptions) => {
  // Should we update menu yet?
  if (Date.now() - options.lastUpdate < options.updateInterval && !options.force) {
    updateMenuOptions = {...options, force: false}
    updateMenuTimeout = setTimeout(() => updateMenu(updateMenuOptions), options.updateInterval)
    return
  }

  options.lastUpdate = Date.now()

  fetch("javascript/check_menu.php").then(resp => {
    resp.text().then(text => {
      var data = text.split("\n")

      if (data.length >= 5) {
        // Messages
        if (parseInt(data[0]) > 0) {
          options.mailImage.src = 'style/' + options.style + '/mail_new.png'
        } else {
          options.mailImage.src = 'style/' + options.style + '/mail.png'
        }

        options.mailImage.title = data[0] + ((parseInt(data[0]) == 1) ? ' nytt meddelande' : ' nya meddelanden')

        // Friends
        if (parseInt(data[1]) > 0) {
          options.friendsImage.src = 'style/' + options.style + '/friends_new.png'
        } else {
          options.friendsImage.src = 'style/' + options.style + '/friends.png'
        }

        options.friendsImage.title = data[1] + ' obesvarade vänförfrågningar'

        // Watched topics
        if (parseInt(data[2]) > 0) {
          options.watchedImage.src = 'style/' + options.style + '/watched_new.png'
        } else {
          options.watchedImage.src = 'style/' + options.style + '/watched.png'
        }

        options.watchedImage.title = data[2] + ' nya bevakade inlägg!'

        // Number of users online
        if (parseInt(data[3]) > 0) {
          options.numUsersOnlineText.innerHTML = data[3]
        }

        // Snowballs
        if (parseInt(data[4]) > 0) {
          fetch("javascript/snowballs.php?time=" + (new Date().getTime())).then(resp => {
            resp.text().then(text => {
              var ids = text.split('\n')

              for (var i = 0; i < ids.length; i++) {
                // Show the snowball
                var snowballURL = "javascript/snowball.php?id=" + ids[i]
                window.open(snowballURL, "snowballWindow" + ids[i], "toolbar=0,location=0,status=0,scrollbars=1,width=350,height=350")
              }
            })
          })
        }

        // Duels, does the image exist?
        if (options.duelsImage) {
          if (parseInt(data[5]) > 0) {
            options.duelsImage.src = 'style/' + options.style + '/duels_new.png'
          } else {
            options.duelsImage.src = 'style/' + options.style + '/duels.png'
          }

          options.duelsImage.title = data[5] + " nya utmaningar eller dueller där det är din tur!"
        }
      }

      updateMenuOptions = {...options, force: false}
      updateMenuTimeout = setTimeout(() => updateMenu(updateMenuOptions), options.updateInterval)
    })
  })
}

export const manualMenuUpdate = () => {
  if (updateMenuOptions) {
    if (updateMenuTimeout)
      clearTimeout(updateMenuTimeout)

    updateMenu({...updateMenuOptions, force: true});
  }
}

const updateDivHandler = (url: string, divId: string, before: number) => (resp) => {
  resp.text().then(text => {
    if (text !== "0\n" || divId !== "content") {
      var content = text.split("\n")

      // Did we come across a hard redirect?
      if (content[0][0] == "#") {
        window.location = content[0].substring(1)
      // Did we come accross a soft redirect?
      } else if (content[0][0] == "$") {
        loadContent(content[0].substring(1))
      } else {
        if (divId === "content") {
          document.title = content[0]
          content.shift()
        }

        const styleElement = document.getElementById('style') as HTMLInputElement
        const style = styleElement.value

        document.getElementById(divId).innerHTML = content.join('\n');
        (document.getElementById("refreshImage") as HTMLImageElement).src = 'style/' + style + '/refresh.png'
        document.body.style.cursor = 'default'

        if (divId === "content" && url.indexOf("#") != -1) {
          // Scroll to the hash tag, if there was one
          var objectID = url.substring(url.lastIndexOf("#") + 1);
          var obj = document.getElementById(objectID)

          if (obj !== null) {
            var y = findPosY(obj)
            window.scroll(0, y)
          }
        }

        const now = Date.now()
        const time = Math.round(now - before) / 1000;

        document.getElementById("time").innerHTML = time.toString();
        (document.getElementById("loadTime") as HTMLInputElement).value = Math.floor(now / 1000).toString();
      }
    }else{
      window.location.href = (document.getElementById("refreshLink") as HTMLAnchorElement).href;
    }
  })
}


export const loadDiv = (divId: string, url, reset) => {
  const before = Date.now();

  if (divId === "content") {
    (document.getElementById("refreshLink") as HTMLAnchorElement).href = url
    window.location.hash = url
    currentUrl = url

    if (isReact(url)) {
      document.getElementById(divId).innerHTML = ''
      return
    }
  }

  (document.getElementById("refreshImage") as HTMLImageElement).src = "images/rotate.gif"
  document.body.style.cursor = 'wait'

  if (reset) {
    document.getElementById(divId).innerHTML = "Laddar..."
  }

  url = insertParam(url, "ajax=1")

  fetch(url).then(updateDivHandler(url, divId, before))
}

export const loadStuff = (url, reset) => loadDiv("content", url, reset)

export const follow = (link) => {
  let url = getPath(link.href)
  url = ".#" + url;
  window.location.href = url;
  currentUrl = "";
  return false;
}

const runOnLoad = (fnc) => {
  if (document.getElementById("loadingComplete") == null) {
    setTimeout(function() { runOnLoad(fnc); }, 100);
  } else {
    fnc();
  }
}

export const loadContent = (url) => {
  var tmp = loadStuff(url, true);

  // Do some JavaScript starts
  if (url == "hang.php") {
    runOnLoad(function() {
      Hangman.reset();
      document.getElementById("guess").focus();
      Hangman.startTimer();
    });
  } else if(url == "settings.php") {
    runOnLoad(() => Scripts.refreshPronouns());
  }

  return tmp;
}

export const sendForm = (form) => {
  const before = Date.now()

  // Handle input tags
  const inputs = form.getElementsByTagName('input');
  const params = [];

  for(let i = 0; i < inputs.length; i++) {
    if(inputs[i].name) {
      if((inputs[i].type !== "checkbox" || inputs[i].checked) && // Checkboxes and radio buttons should only be included if they are checked.
         (inputs[i].type !== "radio" || inputs[i].checked)) {
        params.push(inputs[i].name + '=' + encodeURIComponent(inputs[i].value));
      }
    }
  }

  const selects = form.getElementsByTagName('select');
  for(let i = 0; i < selects.length; i++) {
    if(selects[i].name) {
      params.push(selects[i].name + '=' + encodeURIComponent(selects[i].options[selects[i].selectedIndex].value));
    }
  }

  const textAreas = form.getElementsByTagName('textarea');
  for(let i = 0; i < textAreas.length; i++) {
    if(textAreas[i].name) {
      params.push(textAreas[i].name + '=' + encodeURIComponent(textAreas[i].value));
    }
  }

  let url = getPath(form.action)
  let body = ''

  if (form.method == "post") {
    params.push("ajax=1");
    body = params.join('&');
  } else if (form.method == "get") {
    for(let x in params) {
      url = insertParam(url, params[x])
    }

    window.location.hash = url
    currentUrl = ""
  }

  document.getElementById("content").innerHTML = "Laddar...";

  (document.getElementById("refreshLink") as HTMLAnchorElement).href = url
  window.location.hash = url
  currentUrl = url

  url = insertParam(url, "ajax=1");

  fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: body,
  }).then(updateDivHandler(url, 'content', before))
}

const isReact = (hash) => {
  if (hash.startsWith("conversation"))
    return true;

  if (hash.startsWith("gift-calendars"))
    return true;

  if (hash.startsWith("admin"))
    return true;

  return false;
}

const historyHack = () => {
  var hash = window.location.hash;
  hash = hash.substr(1, hash.length - 1);

  if(hash != currentUrl) {
    if (isReact(hash)) {
      currentUrl = hash
      document.getElementById("content").innerHTML = '';
      (document.getElementById("refreshLink") as HTMLAnchorElement).href = currentUrl
    } else {
      var result = hash.indexOf(".");
      if(result != -1) {
        loadContent(hash)
      }
    }
  }
}

export const load = () => {
  var page = getPath(location.href)

  if(page.indexOf("#") != -1) {
    page = page.substring(0, page.indexOf("#"));
  }

  currentUrl = page;

  setInterval(historyHack, 100);
}

export const refresh = (link) => {
  const url = getPath(link.href)

  if (isReact(url))
    location.reload()
  else
    loadContent(url);

  return false;
}
