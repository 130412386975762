import React from 'react'
import { isLoggedIn } from "../../services/mugglis"

interface AuthRequiredProps {
  error: JSX.Element,
  children?: React.ReactNode,
}

export const AuthRequired: React.FC<AuthRequiredProps> = ({error, children}) => {
  if (!isLoggedIn())
    return error

  return <>{children}</>
}
