var guesses = [];
var correct = 0;
var stage = 0;
var MAX_STAGE = 6;
var wordLength;
var score = 0;
var money;
var word;

var time;
var timerOn = true;
var c = 1;

var letterQueue = [];
var letterQueueRunning = false;

var guessingList = [];

export function reset() {
	guesses = [];
	correct = 0;
	stage = 0;
	MAX_STAGE = 6;
	wordLength;
	score = 0;
	money;
	word;

	time;
	timerOn = true;
	c = 1;

	letterQueue = [];
	letterQueueRunning = false;

	guessingList = [];
}

function statusString() {
	return guessingList.length > 0 ? guessingList.join(", ") : "";
}

var alpha = 'abcdefghijklmnopqrstuvwxyzåäöABCDEFGHIJKLMNOPQRSTUVWXYZÅÄÖ';
function isAlpha(text) {
	for (var i = 0; i < text.length; i++) {
		if (alpha.indexOf(text.charAt(i), 0) == -1) {
			return false;
		}
	}
	
	return true;
}

function updateScreen() {
	var guess_string = guesses.join(", ").toUpperCase();
	
	if (timerOn) {
		score = Math.round(2000 / (1 + Math.log(1 + time, 2)));
	}
	
	document.getElementById("guesses").innerHTML = guess_string;
	document.getElementById("status").innerHTML = statusString();
	document.getElementById("hang").src = "images/hang" + stage + ".png";
	document.getElementById("timer").innerHTML = Math.floor(time);
}

function refreshCorrect()
{
	correct = 0;
	
	for(var i = 0; i < wordLength; i++)
	{
		var char = document.getElementById("char" + i).innerHTML;
		
		if (char != "_")
		{
			correct += 1;
		}
	}
}

function finish()
{
	if (stage < MAX_STAGE)
	{
		// Calculate score
		document.getElementById('result').innerHTML = "<p>Grattis, du fick <b>" + score + " poäng</b>!</p><p>Du fick ihop <b>" + money + "</b>!</p> <p><a href=\"hang.php\" onclick=\"return follow(this);\">Spela igen...</a></p>";
		
		// Turn off queue
		letterQueueRunning = false;
	}
	else
	{
		document.getElementById("result").innerHTML = "Du misslyckades! <a href=\"hang.php\" onclick=\"return follow(this);\">Spela igen...</a>";		
		
		for(var i = 0; i < word.length; i++) {
			if (document.getElementById("char" + i).innerHTML == "_") {
				document.getElementById("char" + i).innerHTML = "<i>" + word[i] + "</i>";
			}
		}
	}

	timerOn = false;
}

export function guessLetter(guess)
{
	// Reset field
	document.getElementById("guess").value = "";
	
	// Make sure it's uppercase
	guess = guess.toUpperCase();

	if(guess.length !== 1 || !isAlpha(guess)) {
		return;
	}
	
	letterQueue.push(guess);
	
	if (!letterQueueRunning) {
		letterQueueRunning = true;
		setTimeout(runLetterQueue, 0);
	}
}

function runLetterQueue()
{
	// Stop the queue
	if (!letterQueueRunning) {
		return;
	}
		
	if (letterQueue.length > 0) {
		const guess = letterQueue.shift();

		if(guessingList.indexOf(guess) == -1) {
			guessingList.push(guess);
			updateScreen();
		}
	
		timerOn = false;

        fetch("javascript/hangman.php?guess=" + encodeURIComponent(guess.toLowerCase()) + "&time=" + time).then(resp => {
            resp.text().then(text => {
                wordLength = document.getElementById("length").value;

                if (text.length > 0) {
                    var test = text.split(":");
                    
                    if (test[0].length > 0) {
                        var result = test[0].split(",");

                        for (var i = 0; i < result.length; i++) {
                            var objectID = "char" + result[i];
                            document.getElementById(objectID).innerHTML = guess.toUpperCase();
                        }

                        refreshCorrect();
                    } else {
                        if (guesses.indexOf(guess) == -1) {
                            guesses.push(guess);

                            if (stage < MAX_STAGE) {
                                stage++;
                            }
                        }
                    }

                    guessingList.splice(guessingList.indexOf(guess), 1);

                    score = parseInt(test[1].replace(',', '.'));

                    c = Math.pow(2, (2000 / score) - 1) - time;

                    score = Math.round(score);

                    document.getElementById("result").innerHTML = score + " poäng";

                    if (test.length >= 3) {
                        if (stage >= MAX_STAGE) {
                            word = test[2].split(",");
                        } else {
                            money = test[2];
                        }
                    }
                }

                updateScreen();

                timerOn = true;

                if (correct >= wordLength || stage >= MAX_STAGE)
                {
                    finish();
                }
            });
        });

		runLetterQueue();
	}else{
		letterQueueRunning = false;
	}
}

function timer(t)
{
	if (timerOn)
	{
		time = t;
		time += 0.1;
		
			
		setTimeout(function() { timer(time); }, 100);
	}
	
	updateScreen();
}

export function startTimer() {
	timerOn = true;
	timer(0);
}
