import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Linkify from 'react-linkify'
import {parse as parseBBCode, TagNode} from '@bbob/parser'
import parseHTML, {convertNodeToElement} from 'react-html-parser'
import replace from 'string-replace-to-array'
import {getUser} from '../../redux/actions'
import {AppState} from '../../redux/reducers'
import {findUser} from '../../redux/state/user'
import {UserLink} from './UserLink'
import {smilieyRows} from './BBCodePanel'

const bbobOptions = {
  onlyAllowTags: [
    'b', 'i', 'u', 's', 'font', 'size', 'color', 'img', 'youtube',
    'spoiler', 'latex', 'math', 'url', 'quote', 'user',
  ],
  onError: (err) => console.warn(err.message, err.lineNumber, err.columnNumber),
}

const hasAttr = (node) => Object.keys(node.attrs).length > 0
const getAttr = (node) => node.attrs[Object.keys(node.attrs)[0]]
const stringContent = (node) => node.content.join('')

const processImage = (node, options: BBCodeOptions) => {
  const url = `images/proxy.php?q=${encodeURIComponent(stringContent(node))}`

  const styledImage = <img src={url} alt={url} style={{position: 'relative', maxWidth: '300px', maxHeight: '100px'}}/>

  if (options.inLink)
    return styledImage
  else
    return <a href={url} target='_blank'>{styledImage}</a>
}

const processYouTube = (node) => {
  let link = stringContent(node)

  let pos = link.indexOf('v=')
  if (pos >= 0) link = link.substr(pos + 2)

  pos = link.indexOf('&')
  if (pos >= 0) link = link.substring(0, pos)

  const url = `https://www.youtube.com/embed/${encodeURIComponent(link)}?rel=0`
  return <iframe width="420" height="315" src={url} style={{border: '1px solid black'}} allowFullScreen></iframe>
}

const processURL = (node, options: BBCodeOptions) => {
  const url = hasAttr(node) ? getAttr(node) : stringContent(node)
  return <a href={url} target='_blank'>{processASTNodes({
    nodes: node.content,
    options: {...options, inLink: true},
  })}</a>
}

const QuoteTag: React.FC<{node: TagNode, options: BBCodeOptions}> = ({node, options}) => {
  const state = useSelector((state: AppState) => state.user)
  const dispatch = useDispatch()

  let credit = <>Citat:</>
  let attr = null
  let user = null

  if (hasAttr(node)) {
    attr = getAttr(node)
    user = findUser(state, attr)

    if (user) {
      credit = <>Skrivet av <UserLink user={user} /></>
    }
  }

  useEffect(() => {
    if (!user && attr)
      dispatch(getUser(attr))
  }, [])

  return <div className="quote"><sup><b>{credit}</b></sup><br />
    {processASTNodes({nodes: node.content, options})}
  </div>
}

const UserTag: React.FC<{name: string}> = ({name}) => {
  const state = useSelector((state: AppState) => state.user)
  const dispatch = useDispatch()

  const user = findUser(state, name)

  useEffect(() => {
    if (!user)
      dispatch(getUser(name))
  }, [])

  if (user)
    return <UserLink user={user} />
  else
    return <>{name}</>
}


const Spoiler: React.FC = (props) => {
  const [visible, setVisible] = useState(false)

  return <div className='quote' onClick={() => setVisible(true)}>
    <b>Spoiler:</b>
    <br />
    {visible ? '' : <i>Tryck här för att visa!</i>}
    <span style={{display: visible ? 'inline' : 'none'}}>
      {props.children}
    </span>
  </div>
}

const addSmilies = (text: string) => {
  smilieyRows.map(row => {
    row.map(smiley => {
      if (smiley.code === ':/') {
        text = replace(text, /(:\/)([^\/])|(:\/)$/g, (_all, first, extra, second, i) => {
          if (first) {
            return <React.Fragment key={i}>
              <img src={`images/smilies/${smiley.image}`} title={smiley.title} alt={first} />
              {extra}
            </React.Fragment>
          } else {
            return <img key={i} src={`images/smilies/${smiley.image}`} title={smiley.title} alt={second} />
          }
        }
        )
      } else {
        text = replace(text, smiley.code, (match, i) =>
          <img key={i} src={`images/smilies/${smiley.image}`} title={smiley.title} alt={match} />
        )
      }
    })
  })

  return text
}

interface BBCodeOptions {
  inLink?: boolean,
  smileys?: boolean,
  html?: boolean,
}

const defaultOptions: BBCodeOptions = {
  inLink: false,
  smileys: true,
  html: false,
}

interface processASTNodeTypes {
  node: any,
  index: number,
  options: BBCodeOptions,
}

const processASTNode = ({node, index, options=defaultOptions}: processASTNodeTypes) => {
  if (node instanceof Object) {
    switch (node.tag) {
      case "b":
        return <b key={index}>{processASTNodes({nodes: node.content, options})}</b>
      case "i":
        return <i key={index}>{processASTNodes({nodes: node.content, options})}</i>
      case "u":
        return <span key={index} style={{textDecoration: 'underline'}}>{processASTNodes({nodes: node.content, options})}</span>
      case "s":
        return <span key={index} style={{textDecoration: 'line-through'}}>{processASTNodes({nodes: node.content, options})}</span>
      case "font":
        return <span key={index} style={{fontFamily: getAttr(node)}}>{processASTNodes({nodes: node.content, options})}</span>
      case "size":
        const size = Math.max(Math.min(32, parseInt(getAttr(node))), 1)
        return <span key={index} style={{fontSize: `${size}pt`}}>{processASTNodes({nodes: node.content, options})}</span>
      case "color":
        const color = getAttr(node)
        return <span key={index} style={{color: color}}>{processASTNodes({nodes: node.content, options})}</span>
      case "img":
        return <React.Fragment key={index}>{processImage(node, options)}</React.Fragment>
      case "youtube":
        return <React.Fragment key={index}>{processYouTube(node)}</React.Fragment>
      case "spoiler":
        if (options.inLink)
          return ''
        else
          return <Spoiler key={index}>{processASTNodes({nodes: node.content, options})}</Spoiler>
      case "latex":
      case "math":
        const latex = stringContent(node)
        return <img key={index} src={`http://latex.codecogs.com/png.latex?\\color{white}${encodeURIComponent(latex)}`} alt='Ekvation' style={{verticalAlign: 'middle'}} />
      case "url":
        if (options.inLink)
          return <React.Fragment key={index}>{processASTNodes({nodes: node.content, options})}</React.Fragment>
        else
          return <React.Fragment key={index}>{processURL(node, options)}</React.Fragment>
      case "quote":
        if (options.inLink)
          return ''
        else
          return <QuoteTag key={index} node={node} options={options} />
      case "user":
        if (options.inLink)
          return ''
        else
          return <UserTag key={index} name={stringContent(node)} />
      default:
        console.error('Invalid BBCode tag', node.tag)
        return ''
    }
  } else {
    if (node === '\n')
      return <br key={index} />

    if (options.smileys)
      node = addSmilies(node)

    if (options.inLink)
      return <React.Fragment key={index}>{node}</React.Fragment>
    else
      return <Linkify key={index} componentDecorator={(href, text, key) => <a href={href} key={key} target="_blank">{text}</a>}>{node}</Linkify>
  }
}

interface processASTNodesTypes {
  nodes: any[],
  options: BBCodeOptions,
}

const processASTNodes = ({nodes, options=defaultOptions}: processASTNodesTypes) => <>
  {nodes.map((node, index) => processASTNode({node, index, options}))}
</>

interface BBCodeProps {
  text: string,
  options?: BBCodeOptions,
}

export const BBCode: React.FC<BBCodeProps> = ({text, options={}}) => {
  options = {...defaultOptions, ...options}

  if (options.html) {
    const lines = parseHTML(text, {
      transform: (node, index) => {
        if (node.type === "tag" && node.name === "a") {
          let {href} = node.attribs
          return <a
            key={index}
            href={href}
            onClick={(e) => {
              if (!href.includes('://')) {
                e.preventDefault()

                window.location.hash = href
              } else {
                return true
              }
            }}>
            {node.children.map(child => convertNodeToElement(child, index))}
          </a>
        }
      },
    })

    return lines.map((element, index) => {
      if (React.isValidElement(element)) {
        return element
      }

      return <BBCode key={index} text={element} />
    })
  }
  const ast = parseBBCode(text, bbobOptions)
  return processASTNodes({nodes: ast, options})
}
