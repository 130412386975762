import { List, Map } from 'immutable'
import { deduplicateList } from "../../utils"
import { GetGiftCalendarUnlockDetailsSuccess, GET_GIFT_CALENDAR, GET_GIFT_CALENDARS_SUCCESS, GET_GIFT_CALENDAR_FAIL, GET_GIFT_CALENDAR_SUCCESS, GET_GIFT_CALENDAR_UNLOCK_DETAILS, GET_GIFT_CALENDAR_UNLOCK_DETAILS_FAIL, GET_GIFT_CALENDAR_UNLOCK_DETAILS_SUCCESS, GET_ITEM_CODES, GET_ITEM_CODES_SUCCESS, LISTEN_FOR_GIFT_CALENDAR_PLACEMENT, PATCH_ITEM_CODE_SUCCESS, PostGiftCalendarUnlockGuess, PostGiftCalendarUnlockGuessFail, PostGiftCalendarUnlockGuessSuccess, POST_GIFT_CALENDAR_SUCCESS, POST_GIFT_CALENDAR_UNLOCK_GUESS, POST_GIFT_CALENDAR_UNLOCK_GUESS_FAIL, POST_GIFT_CALENDAR_UNLOCK_GUESS_SUCCESS, POST_ITEM_CODE_SUCCESS, REGISTER_GIFT_CALENDAR_PLACEMENT, UpdateGiftCalendarUnlockSuccess, UPDATE_GIFT_CALENDAR_UNLOCK_SUCCESS } from "../actions"
import {ObjectCopier} from './base'


const mergeItemCodes = (itemCodes: List<ItemCode>): List<ItemCode> => {
  return deduplicateList(itemCodes, (a: ItemCode, b: ItemCode) => a.id === b.id);
}

export interface ItemCode {
  id: string,
  code: string,
  name: string,
  image: string,
}

export interface ItemCodeState {
  itemCodes: List<ItemCode>,
}

const initialState: ItemCodeState = {
  itemCodes: List(),
}


export const itemCodeReducer = (state = initialState, action): ItemCodeState => {
  switch (action.type) {
    case GET_ITEM_CODES_SUCCESS:
      return {...state, itemCodes: mergeItemCodes(action.itemCodes)};
    case POST_ITEM_CODE_SUCCESS:
      return {...state, itemCodes: mergeItemCodes(state.itemCodes.push(action.itemCode))};
    case PATCH_ITEM_CODE_SUCCESS:
      return {
        ...state,
        itemCodes: state.itemCodes.map(itemCode => {
          if (itemCode.id === action.itemCode.id) {
            return action.itemCode;
          } else {
            return itemCode;
          }
        }),
      }
    default:
      return state
  }
}