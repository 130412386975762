import React from 'react'


interface LinkProps {
  url: string,
  color?: string,
  children: React.ReactNode,
}

export const Link: React.FC<LinkProps> = ({url, color, children}) =>
    color == null ?
    <a href={`#${url}`}>{children}</a>
    :
    <a href={`#${url}`} style={{color: color}}>{children}</a>
