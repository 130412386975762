import React from 'react'


export interface MoneyProps {
  amount: number,
}

export const Money: React.FC<MoneyProps> = ({amount}) => {
  if (amount <= 0)
    return <>"Inga pengar!"</>

  const galleons = Math.floor(amount / 493)
  amount -= 493 * galleons

  const sickles = Math.floor(amount / 29)
  amount -= 29 * sickles

  const knuts = amount

  const parts: string[] = []

  if (galleons == 1)
    parts.push('1 galleon')
  else if (galleons > 1)
    parts.push(galleons + ' galleoner')

  if (sickles == 1)
    parts.push('1 sikel')
  else if (sickles > 1)
    parts.push(sickles + ' siklar')

  if (knuts == 1)
    parts.push('1 knuting')
  else if (knuts > 1)
    parts.push(knuts + ' knutingar')

  return <>{parts.join(", ")}</>
}
