import {GET_CONVERSATIONS, GET_CONVERSATION_SUCCESS, GetConversationSuccess, SEND_CONVERSATION_MESSAGE, GET_CONVERSATION_MESSAGES, MARK_CONVERSATION_READ, GET_CONVERSATIONS_SUCCESS, GetConversationsSuccess, GET_CONVERSATION_MESSAGES_SUCCESS, SEND_CONVERSATION_MESSAGE_SUCCESS, SEND_CONVERSATION_MESSAGE_FAIL, GET_CONVERSATION_FAIL, DELETE_MESSAGE, DeleteMessage} from "../actions"
import {deduplicate, setUnion, setDifference} from '../../utils'
import {User} from "./user"


export interface Message {
  id?: string,
  localId?: string,
  user: User,
  text: string,
  date: Date,
  read: boolean,
  failed: boolean,
}

export interface Conversation {
  id: string,
  user: User,
  messages: Message[],
  cursor: string,
  loading: boolean,
  markReadInFlight: boolean,
}

export interface ChatState {
  loading: boolean,
  conversations: Conversation[],
  failedConversationIds: Set<String>,
  cursor: string,
}

export const findConversation = (state: ChatState, conversationId: string) => {
  return state.conversations.find(c => c.id == conversationId)
}

const mergeConversations = (conversations: Conversation[]): Conversation[] => {
  const convoMap = new Map<String, Conversation>()

  for (const convo of conversations) {
    if (convoMap.has(convo.id)) {
      convoMap.set(convo.id, {
        ...convo,
        messages: mergeMessages(convo.messages.concat(convoMap.get(convo.id).messages))
      })
    } else {
      convoMap.set(convo.id, convo)
    }
  }

  let result = Array.from(convoMap.values())
  result = result.filter((c) => c.messages.length > 0)
  result.sort((c1, c2) => c2.messages[0].date.getTime() - c1.messages[0].date.getTime())
  return result
}

const mergeMessages = (messages: Message[]): Message[] => {
  const result = deduplicate(messages, (m1, m2) => (m1.id === m2.id) || (m1.localId && m1.localId === m2.localId))
  result.sort((m1, m2) => m2.date.getTime() - m1.date.getTime())
  return result
}

const initialChatState: ChatState = {
  conversations: <Conversation[]>[],
  failedConversationIds: new Set<String>(),
  loading: false,
  cursor: null,
}

export const chatReducer = (state = initialChatState, action): ChatState => {
  switch (action.type) {
    case GET_CONVERSATIONS:
      return {...state, loading: true}
    case GET_CONVERSATIONS_SUCCESS:
      action = action as GetConversationsSuccess
      return {
        ...state,
        loading: false,
        conversations: mergeConversations(state.conversations.concat(action.conversations)),
        cursor: action.cursor,
      }
    case GET_CONVERSATION_SUCCESS:
      return {
        ...state,
        conversations: mergeConversations(state.conversations.concat([action.conversation])),
        failedConversationIds: setDifference(state.failedConversationIds, new Set([action.conversationId])),
      }
    case GET_CONVERSATION_FAIL:
      return {
        ...state,
        failedConversationIds: setUnion(state.failedConversationIds, new Set([action.conversationId])),
      }
    case GET_CONVERSATION_MESSAGES:
      return {
        ...state,
        conversations: state.conversations.map(c => {
          if (c.id === action.conversationId)
            return {...c, loading: true}
          else
            return c
        }),
      }
    case GET_CONVERSATION_MESSAGES_SUCCESS:
      return {
        ...state,
        conversations: mergeConversations(state.conversations.map(c => {
          if (c.id === action.conversationId) {
            return {
              ...c,
              messages: mergeMessages(c.messages.concat(action.messages)),
              cursor: action.cursor,
              loading: false,
            }
          } else {
            return c
          }
        })),
      }
    case SEND_CONVERSATION_MESSAGE:
      return {
        ...state,
        conversations: mergeConversations(state.conversations.map(c => {
          if (c.id === action.conversationId) {
            const newMessages: Message[] = [{
              localId: action.localId,
              user: action.user,
              text: action.text,
              date: action.date,
              read: true,
              failed: false,
            }]

            return {
              ...c,
              messages: mergeMessages(newMessages.concat(c.messages)),
            }
          } else {
            return c
          }
        })),
      }
    case SEND_CONVERSATION_MESSAGE_SUCCESS:
      return {
        ...state,
        conversations: mergeConversations(state.conversations.map(c => {
          if (c.id === action.conversationId) {
            return {
              ...c,
              messages: mergeMessages(
                c.messages.filter(m => m.localId != action.message.localId)
                          .concat([action.message])
              ),
            }
          } else {
            return c
          }
        })),
      }
    case SEND_CONVERSATION_MESSAGE_FAIL:
      return {
        ...state,
        conversations: mergeConversations(state.conversations.map(c => {
          if (c.id === action.conversationId) {
            return {
              ...c,
              messages: mergeMessages(
                c.messages.map(m => {
                  if (m.localId === action.localId) {
                    return {...m, failed: true}
                  } else {
                    return m
                  }
                })
              ),
            }
          } else {
            return c
          }
        })),
      }
    case MARK_CONVERSATION_READ:
      return {
        ...state,
        conversations: state.conversations.map(c => {
          if (c.id === action.conversationId) {
            let found = false

            return {
              ...c,
              messages: c.messages.map(m => {
                if (m.id === action.lastMessageReadId || found) {
                  found = true
                  return {...m, read: true}
                } else {
                  console.log('Was not message', m)
                  return m
                }
              }),
            }
          } else {
            return c
          }
        }),
      }
    case DELETE_MESSAGE:
    {
      const actionT = action as DeleteMessage
      return {
        ...state,
        conversations: state.conversations.map(c => {
          return {
            ...c,
            messages: c.messages.filter(m => m.id !== actionT.messageId),
          }
        }),
      }
    }
    default:
      return state
  }
}
