import {postWebsocketToken, websocketMessage, WEBSOCKET_MESSAGE, getConversationMessages} from '../actions'
import {manualMenuUpdate} from '../../modules/ajax'


export const websocketMiddleware = store => {
  import(/* webpackChunkName: "websocket" */ 'socket.io-client').then(({ default: openSocket }) => {
    const socket = openSocket('/')

    socket.on('token', message => {
      store.dispatch(postWebsocketToken(message.token))
    })

    socket.on('message', message => {
      store.dispatch(websocketMessage(message))
    })

    socket.emit('requestToken')
  }).catch(error => 'An error occurred while loading socket.io-client')

  return next => action => {
    next(action)

    switch (action.type) {
      case WEBSOCKET_MESSAGE:
        switch (action.message.name) {
          case "CONVERSATION_UPDATE":
            store.dispatch(getConversationMessages({
              conversationId: action.message.body.conversation_id,
              notify: true,
            }))

            // This is only to hook into the legacy UI
            manualMenuUpdate()
            break
          default:
            break
        }
        break
      default:
        break
    }
  }
}
