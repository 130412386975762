import {ON_GET_USERS, GET_SETTINGS_SUCCESS} from '../actions'
import {deduplicate} from '../../utils'



export interface Team {
  id: string,
  name: string,
  color: string,
}

export interface Status {
  id: number,
  name: string,
}

export interface User {
  id?: string,
  name?: string,
  avatar?: URL,
  team?: Team,
  status?: Status,
  system?: boolean,
  deleted?: boolean,
}

export interface Settings {
  user: User,
  showSmileys: boolean,
  sounds: boolean,
}

export interface UserState {
  users: User[],
  settings: Settings,
}

const mergeUsers = (users: User[]): User[] => {
  return deduplicate(users, (u1, u2) => u1.id === u2.id)
}

export const findUser = (state: UserState, idOrName: string): User => {
  return state.users.find(u => {
    if (u.id === idOrName)
      return true

    if (u.name && u.name.toLowerCase() === idOrName.toLowerCase())
      return true

      return false
  })
}

const initialState: UserState = {
  users: [],
  settings: {
    user: null,
    showSmileys: true,
    sounds: true,
  },
}

export const userReducer = (state = initialState, action): UserState => {
  switch (action.type) {
    case ON_GET_USERS:
      return {
        ...state,
        users: mergeUsers(action.users.concat(state.users)),
      }
    case GET_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: action.settings,
      }
    default:
      return state
  }
}
